// SampleData.js - Generates sample data for graph visualization when no real data exists

const generateSampleData = () => {
   // Sample folders
   const folders = [
     { id: 1, name: 'Security Fundamentals', order: 0 },
     { id: 2, name: 'Network Security', order: 1 },
     { id: 3, name: 'Malware Analysis', order: 2 },
     { id: 4, name: 'Cryptography', order: 3 },
     { id: 5, name: 'Web Security', order: 4 },
   ];
   
   // Sample notes
   const notes = [
     {
       id: 1,
       title: 'CIA Triad',
       folder_id: 1,
       content: 'The CIA triad consists of **Confidentiality**, **Integrity**, and **Availability**. These three principles form the cornerstone of any security program.\n\n- **Confidentiality**: Ensuring information is accessible only to those authorized to have access\n- **Integrity**: Maintaining and assuring the accuracy and completeness of data\n- **Availability**: Ensuring information is available when needed by authorized users\n\nSee also: [[Authentication vs Authorization]]',
       tags: ['security', 'fundamentals']
     },
     {
       id: 2,
       title: 'Authentication vs Authorization',
       folder_id: 1,
       content: '**Authentication** and **Authorization** are two crucial security concepts that are often confused.\n\n**Authentication** verifies WHO you are. Examples include:\n- Username/password combinations\n- Biometric verification\n- Multi-factor authentication\n\n**Authorization** determines WHAT you can access or do. This happens after authentication and includes:\n- Access control lists (ACLs)\n- Role-based access control (RBAC)\n- Attribute-based access control (ABAC)\n\nSee also: [[Multi-factor Authentication]]',
       tags: ['authentication', 'security']
     },
     {
       id: 3,
       title: 'Multi-factor Authentication',
       folder_id: 1,
       content: 'Multi-factor Authentication (MFA) adds security by requiring two or more verification methods:\n\n1. **Something you know** - password, PIN, security question\n2. **Something you have** - security token, smartphone app\n3. **Something you are** - biometrics like fingerprints or facial recognition\n\nMFA significantly reduces the risk of account compromise even if passwords are leaked or stolen.',
       tags: ['authentication', 'mfa', 'security']
     },
     {
       id: 4,
       title: 'Network Firewalls',
       folder_id: 2,
       content: 'Firewalls are network security devices that monitor and filter incoming and outgoing network traffic. Types include:\n\n- **Packet-filtering firewalls**: Examine packets and prevent those that don\'t match rules\n- **Stateful inspection firewalls**: Track active connections\n- **Application layer firewalls**: Operate at the application layer to identify and block malicious traffic\n- **Next-generation firewalls (NGFW)**: Combine traditional firewall tech with additional functionality\n\nFirewalls are essential for enforcing security policies and creating network boundaries.\n\nSee also: [[Network Intrusion Detection Systems]]',
       tags: ['network', 'firewall', 'defense']
     },
     {
       id: 5,
       title: 'Network Intrusion Detection Systems',
       folder_id: 2,
       content: 'Network Intrusion Detection Systems (NIDS) monitor network traffic for suspicious activity and policy violations.\n\n**Types of NIDS:**\n- **Signature-based**: Detects attacks by looking for specific patterns\n- **Anomaly-based**: Detects abnormal behavior compared to normal baseline\n- **Hybrid systems**: Combine both approaches\n\nNIDS can be deployed in-line (like an IPS) or passively. They complement firewalls by providing deeper packet inspection and analysis.\n\nSee also: [[Network Firewalls]]',
       tags: ['network', 'ids', 'monitoring']
     },
     {
       id: 6,
       title: 'Ransomware Analysis',
       folder_id: 3,
       content: 'Ransomware is malware that encrypts victim files and demands payment for the decryption key. Analysis involves:\n\n1. **Static Analysis**: Examining the malware without running it\n2. **Dynamic Analysis**: Observing behavior in a controlled environment\n3. **Memory Analysis**: Examining memory dumps\n\nCommon ransomware behaviors include:\n- Enumerating files and directories\n- Deleting volume shadow copies\n- Encrypting files with specific extensions\n- Creating ransom notes\n\nSee also: [[Malware Analysis Techniques]]',
       tags: ['malware', 'ransomware', 'analysis']
     },
     {
       id: 7,
       title: 'Malware Analysis Techniques',
       folder_id: 3,
       content: 'Malware analysis encompasses various techniques:\n\n**Static Analysis:**\n- File hashing and identification\n- String extraction\n- Header analysis\n- Disassembly and code review\n\n**Dynamic Analysis:**\n- Sandboxing\n- API call monitoring\n- Network traffic analysis\n- Registry and file system changes\n\n**Advanced Techniques:**\n- Code emulation\n- Debugging\n- Memory forensics\n\nA combination of approaches provides the most complete understanding of malware functionality and intent.',
       tags: ['malware', 'analysis', 'reverse-engineering']
     },
     {
       id: 8,
       title: 'Symmetric vs Asymmetric Encryption',
       folder_id: 4,
       content: '**Symmetric Encryption** uses the same key for encryption and decryption.\n- Faster and efficient for large data\n- Examples: AES, DES, 3DES\n- Challenge: secure key exchange\n\n**Asymmetric Encryption** uses a key pair (public and private).\n- Public key encrypts, private key decrypts\n- Slower than symmetric encryption\n- Examples: RSA, ECC, DSA\n- Solves key distribution problem\n\nModern systems often use hybrid approaches: asymmetric for key exchange, symmetric for data encryption.\n\nSee also: [[Public Key Infrastructure]]',
       tags: ['encryption', 'cryptography', 'security']
     },
     {
       id: 9,
       title: 'Public Key Infrastructure',
       folder_id: 4,
       content: 'Public Key Infrastructure (PKI) is a framework for managing digital certificates and public-key encryption.\n\nCore components:\n- **Certificate Authority (CA)**: Issues and verifies certificates\n- **Registration Authority (RA)**: Verifies user identities\n- **Certificate Database**: Stores certificate information\n- **Certificate Store**: Holds issued certificates\n\nPKI enables secure communications, digital signatures, and authentication across untrusted networks like the internet.\n\nSee also: [[Symmetric vs Asymmetric Encryption]]',
       tags: ['pki', 'cryptography', 'certificates']
     },
     {
       id: 10,
       title: 'Cross-Site Scripting (XSS)',
       folder_id: 5,
       content: 'Cross-Site Scripting (XSS) attacks inject malicious scripts into websites viewed by other users.\n\n**Types of XSS:**\n- **Reflected XSS**: Malicious script comes from the current HTTP request\n- **Stored XSS**: Malicious script is stored on the target server\n- **DOM-based XSS**: Vulnerability exists in client-side code\n\n**Prevention:**\n- Input validation and sanitization\n- Output encoding\n- Content Security Policy (CSP)\n- Using modern frameworks with built-in XSS protection\n\nSee also: [[SQL Injection]]',
       tags: ['web', 'xss', 'vulnerability']
     },
     {
       id: 11,
       title: 'SQL Injection',
       folder_id: 5,
       content: 'SQL Injection occurs when untrusted data is sent to an interpreter as part of a command or query.\n\n**Attack vectors:**\n- User input fields\n- HTTP headers\n- Cookie values\n\n**Impact:**\n- Unauthorized data access\n- Data modification or deletion\n- Authentication bypass\n- Command execution\n\n**Prevention:**\n- Prepared statements with parameterized queries\n- Stored procedures\n- Input validation\n- Least privilege database accounts\n\nSQL Injection remains one of the most common web application vulnerabilities.\n\nSee also: [[Cross-Site Scripting (XSS)]]',
       tags: ['web', 'sql-injection', 'database', 'vulnerability']
     },
     {
       id: 12,
       title: 'Threat Intelligence Sources',
       folder_id: null,
       content: 'Effective threat intelligence comes from multiple sources:\n\n**Internal Sources:**\n- Security tools and logs\n- Incident response reports\n- Vulnerability assessments\n\n**External Sources:**\n- Commercial threat feeds\n- Open-source intelligence (OSINT)\n- Government advisories\n- Industry sharing groups\n\n**Communities:**\n- ISACs (Information Sharing and Analysis Centers)\n- Security forums and mailing lists\n\nThe value of threat intelligence depends on relevance, timeliness, accuracy, and actionability.',
       tags: ['threat-intelligence', 'security']
     },
     {
       id: 13,
       title: 'Secure Development Lifecycle',
       folder_id: null,
       content: 'The Secure Development Lifecycle (SDL) integrates security practices into software development:\n\n1. **Training**: Security education for developers\n2. **Requirements**: Define security requirements\n3. **Design**: Threat modeling and security architecture\n4. **Implementation**: Secure coding practices\n5. **Verification**: Security testing\n6. **Release**: Security review and readiness\n7. **Response**: Incident response planning\n\nImplementing SDL reduces vulnerabilities and security-related design/coding flaws.',
       tags: ['sdlc', 'development', 'security']
     },
     {
       id: 14,
       title: 'Zero Trust Security Model',
       folder_id: null,
       content: 'The Zero Trust security model operates on the principle "never trust, always verify." Key concepts include:\n\n- Verify explicitly: Always authenticate and authorize\n- Use least privilege access: Limit user access rights\n- Assume breach: Minimize blast radius and segment access\n\nImplementation components:\n- Strong identity verification\n- Device health validation\n- Strict access controls\n- Micro-segmentation\n- Continuous monitoring\n\nZero Trust acknowledges that threats can be both external and internal, eliminating the concept of a trusted network.',
       tags: ['zero-trust', 'architecture', 'security-model']
     },
     {
       id: 15,
       title: 'Cloud Security Principles',
       folder_id: null,
       content: 'Securing cloud environments requires understanding the shared responsibility model and implementing:\n\n**Identity and Access Management:**\n- Strong authentication\n- Role-based access control\n- Privileged access management\n\n**Data Protection:**\n- Encryption (at rest and in transit)\n- Data classification\n- DLP controls\n\n**Network Security:**\n- Segmentation\n- Secure connectivity\n- Traffic monitoring\n\n**Compliance and Governance:**\n- Policy enforcement\n- Security posture management\n- Audit logging\n\nCloud security differs from traditional approaches due to dynamic infrastructure, API-driven management, and shared responsibility models.',
       tags: ['cloud', 'security', 'architecture']
     }
   ];
 
   // Mark some notes as favorites
   const favorites = [1, 8, 10, 14];
   
   return { notes, folders, favorites };
 };
 
 module.exports = { generateSampleData };