import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { 
  ArrowLeft,
  Save, 
  X, 
  Folder, 
  Menu, 
  Hash, 
  FileText,
  ChevronDown,  // Add this
  ChevronRight  // Add this
} from 'lucide-react';

function NewNote() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await fetch('/api/notes');
        const data = await response.json();
        setFolders(data.folders);
        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
        toast.error('Failed to fetch folders. Please try again.');
        setIsLoading(false);
      }
    };

    fetchFolders();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!title.trim()) {
      toast.error('Title is required');
      return;
    }
    
    setIsSaving(true);
    
    try {
      const response = await fetch('/api/new_note', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title,
          content,
          folder_id: selectedFolder
        })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        toast.success('Note created successfully');
        navigate(`/notes/${data.note.id}`);
      } else {
        toast.error(data.error || 'Failed to create note');
        setIsSaving(false);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while creating the note');
      setIsSaving(false);
    }
  };

  const applyTemplate = (template) => {
    setContent(template.content);
    setShowTemplates(false);
  };

  // Some predefined templates
  const templates = [
    {
      name: 'Meeting Notes',
      content: `# Meeting Notes

## Date: ${new Date().toLocaleDateString()}

## Participants
- 

## Agenda
1. 
2. 
3. 

## Discussion Points

## Action Items
- [ ] 
- [ ] 

## Next Steps

`
    },
    {
      name: 'Security Report',
      content: `# Security Report

## Overview

## Findings
1. **High Severity**
   - 

2. **Medium Severity**
   - 

3. **Low Severity**
   - 

## Recommendations

## Timeline

`
    },
    {
      name: 'Research Notes',
      content: `# Research: [Topic]

## Summary

## Key Points
- 
- 
- 

## Resources
- 

## Questions
- [ ] 
- [ ] 

`
    }
  ];

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-gray-100">
        Loading...
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col bg-gray-900 text-gray-100">
      {/* Top navbar */}
      <div className="flex items-center bg-gray-800 p-2 border-b border-gray-700">
        <button 
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)} 
          className="p-2 rounded hover:bg-gray-700"
          title={sidebarCollapsed ? "Show sidebar" : "Hide sidebar"}
        >
          <Menu size={20} />
        </button>
        
        <div className="flex-grow flex justify-between mx-4">
          <span className="font-medium">New Note</span>
          <div className="flex items-center space-x-2">
            <button 
              onClick={handleSubmit} 
              disabled={isSaving}
              className={`p-2 rounded hover:bg-gray-700 text-green-400 hover:text-green-300 ${isSaving ? 'opacity-50 cursor-not-allowed' : ''}`}
              title="Save note"
            >
              <Save size={18} />
            </button>
            <Link 
              to="/notes" 
              className="p-2 rounded hover:bg-gray-700 text-red-400 hover:text-red-300"
              title="Cancel"
            >
              <X size={18} />
            </Link>
          </div>
        </div>
      </div>
      
      <div className="flex flex-1 overflow-hidden">
        {/* Left sidebar */}
        {!sidebarCollapsed && (
          <div className="w-60 bg-gray-900 border-r border-gray-700 overflow-y-auto">
            <div className="p-4">
              <Link to="/notes" className="flex items-center text-gray-400 hover:text-gray-200 mb-4">
                <ArrowLeft size={16} className="mr-2" />
                Back to Notes
              </Link>
              
              <div className="mb-6">
                <h3 className="text-xs uppercase font-semibold text-gray-500 mb-2">Folder</h3>
                <select
                  value={selectedFolder || ''}
                  onChange={(e) => setSelectedFolder(e.target.value === '' ? null : e.target.value)}
                  className="w-full bg-gray-800 border border-gray-700 rounded p-2 text-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                >
                  <option value="">Uncategorized</option>
                  {folders.map(folder => (
                    <option key={folder.id} value={folder.id}>
                      {folder.name}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="border-t border-gray-800 pt-4">
                <button
                  onClick={() => setShowTemplates(!showTemplates)}
                  className="flex items-center text-gray-400 hover:text-gray-200 w-full text-left mb-2"
                >
                  <FileText size={16} className="mr-2" />
                  <span className="text-sm">Templates</span>
                  {showTemplates ? <ChevronDown size={16} className="ml-auto" /> : <ChevronRight size={16} className="ml-auto" />}
                </button>
                
                {showTemplates && (
                  <div className="pl-6 mt-2 space-y-1">
                    {templates.map((template, index) => (
                      <button
                        key={index}
                        onClick={() => applyTemplate(template)}
                        className="w-full text-left text-gray-400 hover:text-gray-200 hover:bg-gray-800 px-2 py-1 rounded text-sm"
                      >
                        {template.name}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              
              <div className="border-t border-gray-800 pt-4 mt-4">
                <h3 className="text-xs uppercase font-semibold text-gray-500 mb-2">Tags</h3>
                <div className="flex flex-wrap gap-2">
                  <button className="flex items-center bg-blue-900/30 text-blue-400 px-2 py-1 rounded text-xs">
                    <Hash size={12} className="mr-1" />
                    security
                  </button>
                  <button className="flex items-center bg-green-900/30 text-green-400 px-2 py-1 rounded text-xs">
                    <Hash size={12} className="mr-1" />
                    research
                  </button>
                  <button className="flex items-center bg-purple-900/30 text-purple-400 px-2 py-1 rounded text-xs">
                    <Hash size={12} className="mr-1" />
                    windows
                  </button>
                  <button className="flex items-center bg-gray-800 text-gray-400 px-2 py-1 rounded text-xs">
                    <Hash size={12} className="mr-1" />
                    Add tag...
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {/* Main content */}
        <div className="flex-1 overflow-auto p-4">
          <div className="max-w-3xl mx-auto">
            <div className="bg-gray-800 rounded-lg border border-gray-700 p-6">
              <div className="mb-6">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Note title"
                  className="w-full bg-gray-800 border-b border-gray-700 text-2xl font-bold text-gray-100 px-0 py-2 focus:outline-none focus:border-blue-500"
                  autoFocus
                />
              </div>
              
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Start writing your note here..."
                className="w-full h-[500px] bg-gray-800 text-gray-200 focus:outline-none font-mono resize-none"
              />
            </div>
            
            <div className="mt-4 text-gray-500 text-sm flex justify-between items-center">
              <div>
                {isSaving ? 'Saving...' : 'Ready to save'}
              </div>
              <div>
                {content.length} characters • {content.split(/\s+/).filter(Boolean).length} words
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewNote;