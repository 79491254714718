import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import { 
  PlusCircle, 
  Folder, 
  X, 
  GripVertical, 
  FileText, 
  ChevronDown, 
  ChevronRight, 
  Search, 
  Settings, 
  Menu, 
  Hash, 
  Calendar, 
  Clock,
  BookOpen,
  Sidebar,
  BarChart2 as Graph,
  Star,
  Heart,
  Coffee,
  LogOut,
  Zap,
  AlertTriangle,
  CheckCircle,
  Info,
  ZoomIn,
  ZoomOut,
  Minimize,
  RefreshCw
} from 'lucide-react';
import Login from './Login.js';
import GraphView from './GraphView.js';

// Helper function to sanitize graph data
const sanitizeGraphData = (data) => {
  if (!data || !data.nodes || !data.links) {
    return { nodes: [], links: [] };
  }
  
  // Ensure all nodes have required properties
  const validNodes = (data.nodes || []).filter(node => 
    node && typeof node.id !== 'undefined'
  );
  
  // Get a list of valid node IDs
  const nodeIds = validNodes.map(node => node.id);
  
  // Ensure all links reference valid nodes
  const validLinks = (data.links || []).filter(link => {
    if (!link) return false;
    
    // Extract IDs from link source/target (they could be objects or direct IDs)
    const sourceId = typeof link.source === 'object' ? link.source?.id : link.source;
    const targetId = typeof link.target === 'object' ? link.target?.id : link.target;
    
    return nodeIds.includes(sourceId) && nodeIds.includes(targetId);
  });
  
  return { nodes: validNodes, links: validLinks };
};

function ObsidianNotes({ initialViewMode = 'explorer' }) {
  const [folders, setFolders] = useState([]);
  const [notes, setNotes] = useState([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState(initialViewMode); // Use initialViewMode here
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [findInNoteQuery, setFindInNoteQuery] = useState('');
  const [showFindInNote, setShowFindInNote] = useState(false);
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const graphRef = useRef(null);
  
  // Graph specific states
  const [selectedNode, setSelectedNode] = useState(null);
  const [highlightedLinks, setHighlightedLinks] = useState(new Set());
  const [highlightedNodes, setHighlightedNodes] = useState(new Set());
  const [showGraphSettings, setShowGraphSettings] = useState(false);
  const [showGraphSearch, setShowGraphSearch] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation(); // Use location to track current path

  // Graph settings
  const [graphSettings, setGraphSettings] = useState({
    nodeSize: 1.5,
    linkDistance: 140,
    chargeStrength: -200,
    particlesPerLink: 3,
    linkWidth: 1.8
  });

  // Toast variants for better user experience
  const toastSuccess = (message) => toast.success(message, {
    icon: <CheckCircle className="text-green-500" />,
    className: "bg-gray-800 text-white border border-gray-700"
  });
  
  const toastError = (message) => toast.error(message, {
    icon: <AlertTriangle className="text-red-500" />,
    className: "bg-gray-800 text-white border border-gray-700"
  });
  
  const toastInfo = (message) => toast.info(message, {
    icon: <Info className="text-blue-500" />,
    className: "bg-gray-800 text-white border border-gray-700"
  });

  // Monitor URL path to set correct view mode
  useEffect(() => {
    if (location.pathname === '/graph' || location.pathname === '/notes/graph') {
      setViewMode('graph');
    } else if (location.pathname.includes('/favorites')) {
      setViewMode('favorites');
    } else if (location.pathname.includes('/recent')) {
      setViewMode('recent');
    } else if (!location.pathname.includes('/notes/')) {
      setViewMode('explorer');
    }
  }, [location.pathname]);

  const fetchNotes = useCallback(async () => {
    try {
      const response = await fetch('/api/notes');
      const data = await response.json();
      setFolders(data.folders || []);
      setNotes(data.notes || []);
      setIsAuthenticated(data.is_authenticated);
      
      // Load favorites from localStorage
      try {
        const savedFavorites = localStorage.getItem('favorites');
        if (savedFavorites) {
          setFavorites(JSON.parse(savedFavorites));
        }
      } catch (e) {
        console.error("Error parsing favorites:", e);
        setFavorites([]);
      }
      
      // Initialize expanded state for all folders
      const initialExpandedState = {};
      (data.folders || []).forEach(folder => {
        initialExpandedState[folder.id] = true;
      });
      setExpandedFolders({
        ...initialExpandedState,
        'uncategorized': true
      });
      
      // Prepare graph data
      prepareGraphData(data.notes || []);
    } catch (error) {
      console.error('Error:', error);
      toastError('Failed to fetch notes. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Prepare graph data for visualization with error handling
  const prepareGraphData = useCallback((notesData) => {
    try {
      if (!notesData || notesData.length === 0) {
        setGraphData({ nodes: [], links: [] });
        return;
      }
      
      // Create nodes for each note with defensive coding
      const nodes = notesData.map(note => ({
        id: note.id,
        title: note.title || `Note ${note.id}`, // Fallback for missing titles
        val: 1 + (note.content ? Math.min(note.content.length / 500, 3) : 0) * graphSettings.nodeSize, // Size based on content length
        color: note.folder_id ? getFolderColor(note.folder_id) : '#6B7280',
        folderName: folders.find(f => f.id === note.folder_id)?.name || 'Uncategorized',
        folder_id: note.folder_id,
        isFavorite: favorites.includes(note.id),
        content: note.content ? note.content.substring(0, 150) + '...' : '',
        x: Math.random() * 1000 - 500, // Random initial positions for animation
        y: Math.random() * 1000 - 500
      }));
      
      // Create links between notes based on relationships (mentions, tags, etc.)
      const links = [];
      
      // Simple relationship based on folder grouping
      const folderGroups = {};
      notesData.forEach(note => {
        const folderId = note.folder_id || 'uncategorized';
        if (!folderGroups[folderId]) {
          folderGroups[folderId] = [];
        }
        folderGroups[folderId].push(note.id);
      });
      
      // Connect notes within the same folder
      Object.values(folderGroups).forEach(noteIds => {
        if (noteIds.length > 1) {
          for (let i = 0; i < noteIds.length; i++) {
            for (let j = i + 1; j < noteIds.length; j++) {
              links.push({
                source: noteIds[i],
                target: noteIds[j],
                value: 1,
                type: 'folder'
              });
            }
          }
        }
      });
      
      // Also add links based on content similarity or references (simplified)
      notesData.forEach(note => {
        if (note.content) {
          notesData.forEach(otherNote => {
            if (note.id !== otherNote.id && otherNote.title && 
                note.content.toLowerCase().includes(otherNote.title.toLowerCase())) {
              links.push({
                source: note.id,
                target: otherNote.id,
                value: 2, // Stronger connection for direct mentions
                type: 'mention'
              });
            }
          });
        }
      });
      
      // Add colors to links
      const processedLinks = links.map(link => ({
        ...link,
        color: getColorForLinkType(link.type)
      }));
      
      // Sanitize the data before setting it
      const sanitizedData = sanitizeGraphData({ nodes, links: processedLinks });
      setGraphData(sanitizedData);
    } catch (error) {
      console.error('Error preparing graph data:', error);
      setGraphData({ nodes: [], links: [] });
    }
  }, [favorites, folders, graphSettings.nodeSize]);
  
  // Get colors for different link types
  const getColorForLinkType = (type) => {
    switch (type) {
      case 'wiki':
        return '#F43F5E'; // rose-500
      case 'mention':
        return '#3B82F6'; // blue-500
      case 'folder':
        return '#64748B'; // slate-500
      default:
        return '#64748B'; // slate-500
    }
  };
  
  // Get a consistent color for a folder
  const getFolderColor = useCallback((folderId) => {
    const colors = [
      '#F87171', // red-400
      '#FB923C', // orange-400
      '#FBBF24', // amber-400
      '#34D399', // emerald-400
      '#60A5FA', // blue-400
      '#A78BFA', // violet-400
      '#F472B6'  // pink-400
    ];
    
    // Use the folder ID to pick a consistent color
    const colorIndex = typeof folderId === 'number' 
      ? folderId % colors.length 
      : String(folderId).charCodeAt(0) % colors.length;
    
    return colors[colorIndex];
  }, []);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  // Save favorites to localStorage whenever it changes
  useEffect(() => {
    try {
      localStorage.setItem('favorites', JSON.stringify(favorites));
    } catch (e) {
      console.error("Error saving favorites:", e);
    }
    
    // Update graph nodes with new favorite status
    if (graphData.nodes.length > 0) {
      setGraphData(prevData => ({
        ...prevData,
        nodes: prevData.nodes.map(node => ({
          ...node,
          isFavorite: favorites.includes(node.id)
        }))
      }));
    }
  }, [favorites]);

  // Handle graph node click for highlighting
  const handleNodeClick = useCallback(node => {
    // Highlight connections
    const highlightedNodeIds = new Set();
    const highlightedLinkIds = new Set();
    
    if (selectedNode && selectedNode.id === node.id) {
      // Deselect if already selected
      setSelectedNode(null);
      setHighlightedNodes(new Set());
      setHighlightedLinks(new Set());
    } else {
      // Select node and highlight connections
      setSelectedNode(node);
      highlightedNodeIds.add(node.id);
      
      // Find connected links and nodes
      graphData.links.forEach(link => {
        const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
        const targetId = typeof link.target === 'object' ? link.target.id : link.target;
        
        if (sourceId === node.id || targetId === node.id) {
          highlightedLinkIds.add(link.id || `${sourceId}-${targetId}`);
          
          if (sourceId === node.id) {
            highlightedNodeIds.add(targetId);
          } else {
            highlightedNodeIds.add(sourceId);
          }
        }
      });
      
      setHighlightedNodes(highlightedNodeIds);
      setHighlightedLinks(highlightedLinkIds);
    }
  }, [graphData.links, selectedNode]);
  
  // Handle navigation to note
  const handleNodeDoubleClick = useCallback(node => {
    navigate(`/notes/${node.id}`);
  }, [navigate]);

  const toggleFavorite = (noteId) => {
    if (favorites.includes(noteId)) {
      setFavorites(favorites.filter(id => id !== noteId));
      toastInfo('Removed from favorites');
    } else {
      setFavorites([...favorites, noteId]);
      toastSuccess('Added to favorites');
    }
  };

  const handleLogin = async (success) => {
    if (success) {
      setIsAuthenticated(true);
      setShowLoginForm(false);
      await fetchNotes();
      toastSuccess('Login successful. Welcome back!');
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        credentials: 'include',
      });
      const data = await response.json();
      if (data.success) {
        setIsAuthenticated(false);
        await fetchNotes();
        toastInfo('Logged out successfully');
      } else {
        console.error('Logout failed:', data.error);
        toastError('Logout failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      toastError('An error occurred during logout. Please try again.');
    }
  };

  const handleAddNote = () => {
    navigate('/new_note');
  };

  const createFolder = async (e) => {
    e.preventDefault();
    if (!newFolderName.trim()) {
      toastError('Folder name cannot be empty');
      return;
    }
    
    try {
      const response = await fetch('/api/create_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ folder_name: newFolderName })
      });
      const data = await response.json();
      if (data.success) {
        setFolders([...folders, data.folder]);
        setNewFolderName('');
        setShowNewFolderInput(false);
        toastSuccess('Folder created successfully');
        
        // Set new folder as expanded
        setExpandedFolders({
          ...expandedFolders,
          [data.folder.id]: true
        });
      } else {
        toastError('Failed to create folder: ' + data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      toastError('An error occurred while creating the folder.');
    }
  };
  
  const deleteFolder = async (folderId) => {
    if (window.confirm('Are you sure you want to delete this folder? All notes will be moved to Uncategorized.')) {
      try {
        const response = await fetch('/api/delete_folder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ folder_id: folderId })
        });
        const data = await response.json();
        if (data.success) {
          setFolders(folders.filter(folder => folder.id !== folderId));
          setNotes(notes.map(note => 
            note.folder_id === folderId ? {...note, folder_id: null} : note
          ));
          toastSuccess('Folder deleted successfully');
          
          // Update graph data after folder deletion
          prepareGraphData(notes.map(note => 
            note.folder_id === folderId ? {...note, folder_id: null} : note
          ));
        } else {
          toastError('Failed to delete folder: ' + data.error);
        }
      } catch (error) {
        console.error('Error:', error);
        toastError('An error occurred while deleting the folder.');
      }
    }
  };

  const updateNoteFolder = async (noteId, folderId) => {
    try {
      const response = await fetch('/api/update_note_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ note_id: noteId, folder_id: folderId })
      });
      const data = await response.json();
      if (!data.success) {
        setNotes(prevNotes => prevNotes.map(note => 
          note.id === noteId ? {...note, folder_id: note.folder_id} : note
        ));
        toastError('Failed to update note folder: ' + data.error);
      } else {
        // Update graph data after changing folder
        const updatedNotes = notes.map(note => 
          note.id === noteId ? {...note, folder_id: folderId} : note
        );
        prepareGraphData(updatedNotes);
      }
    } catch (error) {
      console.error('Error:', error);
      setNotes(prevNotes => prevNotes.map(note => 
        note.id === noteId ? {...note, folder_id: note.folder_id} : note
      ));
      toastError('An error occurred while updating the note folder.');
    }
  };

  const toggleFolderExpanded = (folderId) => {
    setExpandedFolders({
      ...expandedFolders,
      [folderId]: !expandedFolders[folderId]
    });
  };

  const onDragEnd = (result) => {
    if (!isAuthenticated) return;
  
    const { destination, source, type, draggableId } = result;
  
    if (!destination) {
      return;
    }
  
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
  
    if (type === 'folder') {
      const newFolders = Array.from(folders);
      const [reorderedFolder] = newFolders.splice(source.index, 1);
      newFolders.splice(destination.index, 0, reorderedFolder);
  
      setFolders(newFolders);
  
      // Send the new order to the server
      const folderOrder = newFolders.map(folder => folder.id);
      fetch('/api/update_folder_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ folder_order: folderOrder })
      })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          toastError('Failed to update folder order: ' + data.error);
          // Revert to the original order if the server update fails
          fetchNotes();
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toastError('An error occurred while updating folder order.');
        // Revert to the original order if the server update fails
        fetchNotes();
      });
    } else if (type === 'note') {
      const newFolderId = destination.droppableId === 'uncategorized' ? null : parseInt(destination.droppableId);
      const noteId = parseInt(draggableId);
  
      // Optimistic update
      setNotes(prevNotes => prevNotes.map(note => 
        note.id === noteId ? {...note, folder_id: newFolderId} : note
      ));
  
      // Server update
      updateNoteFolder(noteId, newFolderId);
    }
  };

  // Filter notes based on search term and current view mode
  const getFilteredNotes = () => {
    let filtered = [...notes];
    
    // Apply search term filter
    if (searchTerm) {
      filtered = filtered.filter(note => 
        note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (note.content && note.content.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    
    // Apply view-specific filters
    if (viewMode === 'favorites') {
      filtered = filtered.filter(note => favorites.includes(note.id));
    }
    
    return filtered;
  };

  const renderNotes = (folderNotes, folderId) => {
    // Filter notes by search term if applicable
    const notesToRender = searchTerm ? 
      folderNotes.filter(note => 
        note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (note.content && note.content.toLowerCase().includes(searchTerm.toLowerCase()))
      ) : 
      folderNotes;
      
    if (notesToRender.length === 0) return null;
    
    return (
      <Droppable droppableId={folderId === null ? 'uncategorized' : folderId.toString()} type="note" isDropDisabled={!isAuthenticated}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`pl-4 transition-colors duration-200 ease-in-out ${
              snapshot.isDraggingOver ? 'bg-gray-700/40' : ''
            }`}
          >
            <AnimatePresence>
              {notesToRender.map((note, index) => (
                <Draggable key={note.id} draggableId={note.id.toString()} index={index} isDragDisabled={!isAuthenticated}>
                  {(provided, snapshot) => (
                    <motion.div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.2 }}
                      className={`
                        py-1 rounded transition-all duration-200 ease-in-out flex items-center text-sm
                        ${snapshot.isDragging ? 'bg-gray-600' : 'hover:bg-gray-600/50'}
                        ${!isAuthenticated ? 'cursor-default' : ''}
                        ${favorites.includes(note.id) ? 'border-l-2 border-red-500 pl-1' : ''}
                      `}
                      style={{
                        ...provided.draggableProps.style,
                      }}
                    >
                      <div {...provided.dragHandleProps} className="mr-1 cursor-grab active:cursor-grabbing" title="Drag to move note">
                        <GripVertical size={14} className="text-gray-400 hover:text-gray-200 transition-colors" />
                      </div>
                      <FileText size={14} className="mr-1 text-gray-400" />
                      <Link to={`/notes/${note.id}`} className="text-gray-300 hover:text-white transition-colors flex-grow truncate">
                        {note.title}
                      </Link>
                      <button 
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleFavorite(note.id);
                        }}
                        className={`mr-1 transition-colors ${favorites.includes(note.id) ? 'text-red-500 hover:text-red-400' : 'text-gray-500 hover:text-gray-300'}`}
                        title={favorites.includes(note.id) ? "Remove from favorites" : "Add to favorites"}
                      >
                        <Heart size={14} fill={favorites.includes(note.id) ? "currentColor" : "none"} />
                      </button>
                    </motion.div>
                  )}
                </Draggable>
              ))}
            </AnimatePresence>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  const renderFolderTree = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="folders" type="folder" isDropDisabled={!isAuthenticated}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="mb-4"
            >
              <div className="text-gray-400 text-xs uppercase font-semibold px-2 py-1 bg-gradient-to-r from-gray-800 to-gray-900 rounded mb-2">Folders</div>
              
              <AnimatePresence>
                {folders.map((folder, index) => (
                  <Draggable key={folder.id} draggableId={folder.id.toString()} index={index} isDragDisabled={!isAuthenticated}>
                    {(provided) => (
                      <motion.div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.2 }}
                        className="mb-1"
                      >
                        <div className="flex items-center group text-gray-300 hover:text-white px-2 py-1 rounded hover:bg-gray-700/50">
                          <div onClick={() => toggleFolderExpanded(folder.id)} className="cursor-pointer mr-1">
                            {expandedFolders[folder.id] ? 
                              <ChevronDown size={16} className="text-gray-400" /> : 
                              <ChevronRight size={16} className="text-gray-400" />
                            }
                          </div>
                          <Folder size={16} className="mr-2" style={{ color: getFolderColor(folder.id) }} />
                          <span 
                            className="flex-grow truncate text-sm" 
                            onClick={() => toggleFolderExpanded(folder.id)}
                            style={{ 
                              color: expandedFolders[folder.id] ? getFolderColor(folder.id) : 'inherit'
                            }}
                          >
                            {folder.name}
                          </span>
                          <div className="hidden group-hover:flex items-center">
                            {folder.id !== 'uncategorized' && isAuthenticated && (
                              <button onClick={() => deleteFolder(folder.id)} className="text-gray-400 hover:text-red-300 transition-colors" title="Delete folder">
                                <X size={16} />
                              </button>
                            )}
                            <div {...provided.dragHandleProps} className="ml-1 cursor-grab active:cursor-grabbing text-gray-400 hover:text-gray-200" title="Drag to reorder folder">
                              <GripVertical size={16} />
                            </div>
                          </div>
                        </div>
                        <AnimatePresence>
                          {expandedFolders[folder.id] && (
                            <motion.div
                              initial={{ opacity: 0, height: 0 }}
                              animate={{ opacity: 1, height: 'auto' }}
                              exit={{ opacity: 0, height: 0 }}
                              transition={{ duration: 0.2 }}
                            >
                              {renderNotes(notes.filter(note => note.folder_id === folder.id), folder.id)}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </motion.div>
                    )}
                  </Draggable>
                ))}
              </AnimatePresence>
              {provided.placeholder}
              
              <div className="mt-2">
                <div className="flex items-center group text-gray-300 hover:text-white px-2 py-1 rounded hover:bg-gray-700/50">
                  <div className="cursor-pointer mr-1">
                    {expandedFolders['uncategorized'] !== false ? 
                      <ChevronDown size={16} className="text-gray-400" onClick={() => toggleFolderExpanded('uncategorized')} /> : 
                      <ChevronRight size={16} className="text-gray-400" onClick={() => toggleFolderExpanded('uncategorized')} />
                    }
                  </div>
                  <Folder size={16} className="mr-2 text-gray-400" />
                  <span className="flex-grow truncate text-sm" onClick={() => toggleFolderExpanded('uncategorized')}>
                    Uncategorized
                  </span>
                </div>
                <AnimatePresence>
                  {expandedFolders['uncategorized'] !== false && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      {renderNotes(notes.filter(note => note.folder_id === null), null)}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const renderRecentFiles = () => {
    // Sort by most recent first (you may need to add a lastModified field to your notes)
    const filteredNotes = getFilteredNotes();
    const sortedNotes = [...filteredNotes].sort((a, b) => b.id - a.id).slice(0, 10);
    
    return (
      <div className="mb-4">
        <div className="text-gray-400 text-xs uppercase font-semibold px-2 py-1 bg-gradient-to-r from-gray-800 to-gray-900 rounded mb-2">Recent Files</div>
        <AnimatePresence>
          {sortedNotes.length === 0 ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-gray-500 text-sm italic p-2"
            >
              No recent files found
            </motion.div>
          ) : (
            sortedNotes.map((note, index) => (
              <motion.div 
                key={note.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.15, delay: index * 0.05 }}
                className={`
                  flex items-center group text-gray-300 hover:text-white px-2 py-1 rounded hover:bg-gray-700/50
                  ${favorites.includes(note.id) ? 'border-l-2 border-red-500 pl-1' : ''}
                `}
              >
                <FileText size={16} className="mr-2 text-gray-400" />
                <Link to={`/notes/${note.id}`} className="flex-grow truncate text-sm">
                  {note.title}
                </Link>
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleFavorite(note.id);
                  }}
                  className={`transition-colors ${favorites.includes(note.id) ? 'text-red-500 hover:text-red-400' : 'text-gray-500 hover:text-gray-300'}`}
                  title={favorites.includes(note.id) ? "Remove from favorites" : "Add to favorites"}
                >
                  <Heart size={14} fill={favorites.includes(note.id) ? "currentColor" : "none"} />
                </button>
              </motion.div>
            ))
          )}
        </AnimatePresence>
      </div>
    );
  };
  
  const renderFavorites = () => {
    const favoriteNotes = notes.filter(note => favorites.includes(note.id));
    const filteredFavorites = searchTerm ? 
      favoriteNotes.filter(note => 
        note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (note.content && note.content.toLowerCase().includes(searchTerm.toLowerCase()))
      ) : 
      favoriteNotes;
    
    return (
      <div className="mb-4">
        <div className="text-gray-400 text-xs uppercase font-semibold px-2 py-1 bg-gradient-to-r from-gray-800 to-gray-900 rounded mb-2">Favorites</div>
        <AnimatePresence>
          {filteredFavorites.length === 0 ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-gray-500 text-sm italic p-2"
            >
              {searchTerm ? "No matching favorites found" : "No favorites yet"}
            </motion.div>
          ) : (
            filteredFavorites.map((note, index) => (
              <motion.div 
                key={note.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.15, delay: index * 0.05 }}
                className="flex items-center group text-gray-300 hover:text-white px-2 py-1 rounded hover:bg-gray-700/50 border-l-2 border-red-500 pl-1"
              >
                <FileText size={16} className="mr-2 text-gray-400" />
                <Link to={`/notes/${note.id}`} className="flex-grow truncate text-sm">
                  {note.title}
                </Link>
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleFavorite(note.id);
                  }}
                  className="text-red-500 hover:text-red-400 transition-colors"
                  title="Remove from favorites"
                >
                  <Heart size={14} fill="currentColor" />
                </button>
              </motion.div>
            ))
          )}
        </AnimatePresence>
      </div>
    );
  };

  // Information panel about the graph view (shown in sidebar)
  const renderGraphSidebarInfo = () => {
    return (
      <div className="mb-4">
        <div className="text-gray-400 text-xs uppercase font-semibold px-2 py-1 bg-gradient-to-r from-gray-800 to-gray-900 rounded mb-2">Knowledge Graph</div>
        
        <div className="bg-gray-800 bg-opacity-30 p-3 rounded-lg border border-gray-700 text-sm">
          <p className="text-gray-300 mb-3">
            Exploring your knowledge graph in the main view.
          </p>
          
          <div className="text-xs space-y-2">
            <div className="text-gray-400 font-medium mb-1">Legend</div>
            <div className="flex items-center mb-1">
              <div className="w-12 h-1 bg-red-500 opacity-80 mr-2 rounded-full"></div>
              <span className="text-gray-300">Wiki Link</span>
            </div>
            <div className="flex items-center mb-1">
              <div className="w-12 h-1 bg-blue-500 opacity-70 mr-2 rounded-full"></div>
              <span className="text-gray-300">Content Mention</span>
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 rounded-full bg-yellow-400 mr-2"></div>
              <span className="text-gray-300">Favorite Note</span>
            </div>
          </div>
          
          <div className="mt-4 space-y-1">
            <div className="text-gray-400 text-xs font-medium mb-1">Statistics</div>
            <div className="flex justify-between items-center text-xs">
              <span className="text-gray-400">Total Notes</span>
              <span className="text-gray-200 font-medium">{notes.length}</span>
            </div>
            <div className="flex justify-between items-center text-xs">
              <span className="text-gray-400">Connected Notes</span>
              <span className="text-gray-200 font-medium">{Math.min(notes.length, graphData.links.length)}</span>
            </div>
            <div className="flex justify-between items-center text-xs">
              <span className="text-gray-400">Connections</span>
              <span className="text-gray-200 font-medium">{graphData.links.length}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleFindInNote = (noteId) => {
    if (findInNoteQuery) {
      navigate(`/notes/${noteId}?search=${encodeURIComponent(findInNoteQuery)}`);
      setShowFindInNote(false);
      setFindInNoteQuery('');
    }
  };

  // Modify view mode handler to navigate between routes with replace option
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    
    try {
      // Navigate to the appropriate route when changing view mode
      // Using replace: true to avoid stacking history entries
      switch (mode) {
        case 'graph':
          navigate('/notes/graph', { replace: true });
          break;
        case 'recent':
          navigate('/notes/recent', { replace: true });
          break;
        case 'favorites':
          navigate('/notes/favorites', { replace: true });
          break;
        case 'explorer':
        default:
          navigate('/notes', { replace: true });
          break;
      }
    } catch (error) {
      console.error('Navigation error:', error);
      // Fallback to just changing the view mode without navigation
      toastError('Navigation error, but view mode has been updated.');
    }
  };

  // Graph panel with the actual visualization
  const renderGraphPanel = () => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="flex flex-col items-center">
            <div className="w-12 h-12 border-4 border-red-500 border-t-transparent rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-400">Loading graph...</p>
          </div>
        </div>
      );
    }
    
    if (!graphData.nodes.length) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="text-center p-6 max-w-md">
            <div className="text-6xl mb-4">🔍</div>
            <h3 className="text-xl font-medium mb-2">No notes found</h3>
            <p className="text-gray-400 mb-6">There are no notes to display in the graph view.</p>
            <button 
              onClick={() => navigate('/new_note')}
              className="px-4 py-2 bg-red-600 hover:bg-red-500 rounded"
            >
              Create a note
            </button>
          </div>
        </div>
      );
    }

    // Use the enhanced GraphView component
    return (
      <GraphView
        notes={notes}
        folders={folders}
        favorites={favorites}
        onNavigateToNote={(node) => navigate(`/notes/${node.id}`)}
        onCreateNote={() => navigate('/new_note')}
        onUpdateNote={(noteId, updates) => {
          // Implement update logic if needed
          console.log('Updating note:', noteId, updates);
        }}
        onDeleteNote={(noteId) => {
          // Implement delete logic if needed
          console.log('Deleting note:', noteId);
        }}
        onBackToExplorer={() => setViewMode('explorer')}
        isMainView={true}
      />
    );
  };

  const RecentContent = () => {
    return (
      <div className="p-4">
        <h1 className="text-xl font-bold mb-4 text-red-500">Recent Notes</h1>
        {/* Your recent notes content for the main area */}
        <div className="max-w-3xl mx-auto">
          {renderRecentFiles()}
        </div>
      </div>
    );
  };
  
  const FavoritesContent = () => {
    return (
      <div className="p-4">
        <h1 className="text-xl font-bold mb-4 text-red-500">Favorite Notes</h1>
        {/* Your favorites content for the main area */}
        <div className="max-w-3xl mx-auto">
          {renderFavorites()}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-black text-white">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 relative">
            <div className="absolute inset-0 border-4 border-red-500 rounded-full opacity-25 animate-ping"></div>
            <div className="absolute inset-0 border-4 border-t-red-500 border-r-transparent border-b-transparent border-l-transparent rounded-full animate-spin"></div>
          </div>
          <p className="mt-4 text-xl font-medium">Loading your notes...</p>
        </div>
      </div>
    );
  }

  // Render the welcome message component
  const WelcomeMessage = () => (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-3xl mx-auto p-4"
    >
      <div 
        className="bg-gray-900 rounded-lg border border-gray-800 p-6 shadow-lg"
        style={{
          backgroundImage: `radial-gradient(#ffffff 1px, transparent 1px), radial-gradient(#ffffff 1px, transparent 1px)`,
          backgroundSize: '50px 50px',
          backgroundPosition: '0 0, 25px 25px',
          opacity: 1,
          backgroundBlendMode: 'color-burn',
        }}
      >
        <div className="flex flex-col items-center justify-center text-center p-8">
          <motion.div 
            className="mb-6"
            initial={{ rotateY: 0 }}
            animate={{ rotateY: 360 }}
            transition={{ duration: 2, delay: 0.5, repeat: 0 }}
          >
            <BookOpen size={60} className="text-red-500" />
          </motion.div>
          <motion.h2 
            className="text-2xl font-semibold mb-4 text-red-500"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            Welcome to your Cybersecurity Notes
          </motion.h2>
          <motion.p 
            className="text-gray-200 mb-6 bg-gray-900/70 px-4 py-2 rounded-md max-w-lg"
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            Select a note from the sidebar or create a new note to get started.
            Your centralized platform for secure cybersecurity knowledge management.
          </motion.p>
          
          {isAuthenticated ? (
            <motion.button 
              onClick={handleAddNote} 
              className="flex items-center bg-gradient-to-r from-red-600 to-red-400 hover:from-red-500 hover:to-red-300 text-white px-6 py-3 rounded-lg shadow-lg transition-colors"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.6 }}
            >
              <PlusCircle size={20} className="mr-2" />
              Create New Note
            </motion.button>
          ) : (
            <motion.button 
              onClick={() => setShowLoginForm(true)} 
              className="flex items-center bg-gray-800 hover:bg-gray-700 text-white px-6 py-3 rounded-lg shadow-lg transition-colors"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.6 }}
            >
              <Settings size={20} className="mr-2" />
              Login to Create Notes
            </motion.button>
          )}
        </div>
      </div>
    </motion.div>
  );

  return (
    <div className="h-screen flex flex-col bg-black text-white">
      {/* Top navbar */}
      <div className="flex items-center bg-gray-900 p-2 border-b border-gray-800">
        <button 
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)} 
          className="p-2 rounded hover:bg-gray-800 transition-colors"
          title={sidebarCollapsed ? "Show sidebar" : "Hide sidebar"}
        >
          <Menu size={20} />
        </button>
        <div className="flex-grow flex justify-between items-center mx-4">
          <div className="flex items-center">
            <Zap size={20} className="mr-2 text-red-500" />
            <span className="font-medium bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">
              Cybersecurity Notes
            </span>
          </div>
          <div className="flex items-center space-x-2">
            {isAuthenticated ? (
              <>
                <motion.button 
                  onClick={handleAddNote} 
                  className="p-2 rounded hover:bg-gray-800 transition-colors" 
                  title="Create new note"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <PlusCircle size={20} className="text-blue-400" />
                </motion.button>
                <motion.button 
                  onClick={handleLogout} 
                  className="p-2 rounded hover:bg-gray-800 transition-colors" 
                  title="Logout"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <LogOut size={20} className="text-red-400" />
                </motion.button>
              </>
            ) : (
              <motion.button 
                onClick={() => setShowLoginForm(true)} 
                className="p-2 rounded hover:bg-gray-800 transition-colors" 
                title="Login"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <Settings size={20} className="text-gray-400" />
              </motion.button>
            )}
          </div>
        </div>
      </div>
      
      <div className="flex flex-1 overflow-hidden">
        {/* Left sidebar */}
        {!sidebarCollapsed && (
          <motion.div 
            initial={{ width: 0, opacity: 0 }}
            animate={{ width: "240px", opacity: 1 }}
            exit={{ width: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="w-60 bg-gray-900 border-r border-gray-800 flex flex-col overflow-hidden"
          >
            {/* Search bar */}
            <div className="p-2">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search size={16} className="text-gray-400" />
                </div>
                <input
                  type="text"
                  className="w-full bg-gray-800 text-white text-sm rounded-md px-4 py-2 pl-9 border border-gray-700 focus:outline-none focus:ring-1 focus:ring-red-500 transition-all"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            
            {/* Navigation buttons */}
            <div className="nav-tabs">
              <button 
                className={`nav-tab ${viewMode === 'explorer' ? 'active' : ''}`}
                onClick={() => handleViewModeChange('explorer')}
              >
                <Sidebar size={14} className="nav-tab-icon" />
                <span>Explorer</span>
              </button>
              <button 
                className={`nav-tab ${viewMode === 'recent' ? 'active' : ''}`}
                onClick={() => handleViewModeChange('recent')}
              >
                <Clock size={14} className="nav-tab-icon" />
                <span>Recent</span>
              </button>
              <button 
                className={`nav-tab ${viewMode === 'favorites' ? 'active' : ''}`}
                onClick={() => handleViewModeChange('favorites')}
              >
                <Heart size={14} className="nav-tab-icon" />
                <span>Favorites</span>
              </button>
              <button 
                className={`nav-tab ${viewMode === 'graph' ? 'active' : ''}`}
                onClick={() => handleViewModeChange('graph')}
              >
                <Graph size={14} className="nav-tab-icon" />
                <span>Graph</span>
              </button>
            </div>
            
            {/* Folders/content area */}
            <div className="overflow-y-auto flex-1 px-2 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900">
              {viewMode === 'explorer' && renderFolderTree()}
              {viewMode === 'recent' && renderRecentFiles()}
              {viewMode === 'favorites' && renderFavorites()}
              {viewMode === 'graph' && renderGraphSidebarInfo()} {/* Show info instead of graph */}
              
              {isAuthenticated && viewMode === 'explorer' && (
                <div className="mt-4 px-2">
                  {showNewFolderInput ? (
                    <motion.form 
                      onSubmit={createFolder} 
                      className="p-2 bg-gray-800 rounded-lg border border-gray-700"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                    >
                      <input
                        type="text"
                        value={newFolderName}
                        onChange={(e) => setNewFolderName(e.target.value)}
                        placeholder="New folder name"
                        className="w-full px-3 py-2 bg-gray-900 text-white text-sm rounded border border-gray-700 focus:outline-none focus:ring-1 focus:ring-red-500"
                        autoFocus
                      />
                      <div className="flex mt-2">
                        <motion.button 
                          type="submit" 
                          className="flex-1 bg-red-600 hover:bg-red-500 text-white px-3 py-1 rounded text-sm mr-2 transition-colors"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          Create
                        </motion.button>
                        <motion.button 
                          onClick={() => setShowNewFolderInput(false)} 
                          className="flex-1 bg-gray-700 hover:bg-gray-600 text-white px-3 py-1 rounded text-sm transition-colors"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          Cancel
                        </motion.button>
                      </div>
                    </motion.form>
                  ) : (
                    <motion.button 
                      onClick={() => setShowNewFolderInput(true)} 
                      className="flex items-center text-gray-400 hover:text-white text-sm bg-gray-800/50 hover:bg-gray-800 p-2 rounded-md w-full transition-colors"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <PlusCircle size={16} className="mr-1" />
                      New Folder
                    </motion.button>
                  )}
                </div>
              )}
              
              {/* Find in Note functionality */}
              {isAuthenticated && (
                <div className="mt-4 px-2">
                  {showFindInNote ? (
                    <motion.div 
                      className="p-2 bg-gray-800 rounded-lg border border-gray-700"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                    >
                      <div className="relative">
                        <input
                          type="text"
                          value={findInNoteQuery}
                          onChange={(e) => setFindInNoteQuery(e.target.value)}
                          placeholder="Search in notes..."
                          className="w-full px-3 py-2 bg-gray-900 text-white text-sm rounded border border-gray-700 focus:outline-none focus:ring-1 focus:ring-red-500"
                          autoFocus
                        />
                      </div>
                      <div className="mt-2 max-h-40 overflow-auto">
                        {notes.filter(note => 
                          note.content && note.content.toLowerCase().includes(findInNoteQuery.toLowerCase())
                        ).slice(0, 5).map(note => (
                          <div 
                            key={note.id} 
                            className="py-1 text-sm cursor-pointer hover:bg-gray-700 rounded px-1"
                            onClick={() => handleFindInNote(note.id)}
                          >
                            <div className="font-medium">{note.title}</div>
                            <div className="text-gray-400 text-xs truncate">
                              {note.content.split(new RegExp(`(${findInNoteQuery})`, 'i')).map((part, i) => 
                                part.toLowerCase() === findInNoteQuery.toLowerCase() 
                                  ? <span key={i} className="bg-red-500/30 text-white">{part}</span> 
                                  : part
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex mt-2">
                        <motion.button 
                          onClick={() => setShowFindInNote(false)} 
                          className="flex-1 bg-gray-700 hover:bg-gray-600 text-white px-3 py-1 rounded text-sm transition-colors"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          Close
                        </motion.button>
                      </div>
                    </motion.div>
                  ) : (
                    <motion.button 
                      onClick={() => setShowFindInNote(true)} 
                      className="flex items-center text-gray-400 hover:text-white text-sm bg-gray-800/50 hover:bg-gray-800 p-2 rounded-md w-full transition-colors"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <Search size={16} className="mr-1" />
                      Find in Notes
                    </motion.button>
                  )}
                </div>
              )}
            </div>
          </motion.div>
        )}
        
        {/* Main content */}
        <div className="flex-1 overflow-auto bg-black">
          {viewMode === 'graph' && renderGraphPanel()}
          {viewMode === 'recent' && <RecentContent />}
          {viewMode === 'favorites' && <FavoritesContent />}
          {viewMode === 'explorer' && (
            showLoginForm && !isAuthenticated ? (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="max-w-md mx-auto mt-8 bg-gray-900 p-6 rounded-lg border border-gray-800 shadow-lg"
              >
                <h2 className="text-xl font-bold mb-4 text-red-500">Login</h2>
                <Login onLogin={handleLogin} />
              </motion.div>
            ) : (
              <WelcomeMessage />
            )
          )}
        </div>
      </div>
      
      {/* Add this style block to ensure the nav tabs look right */}
      <style jsx="true">{`
        .nav-tabs {
          display: flex;
          border-bottom: 1px solid #1f2937;
          margin-bottom: 10px;
        }
        .nav-tab {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 8px 0;
          font-size: 11px;
          color: #9ca3af;
          border-bottom: 2px solid transparent;
          transition: all 0.2s;
        }
        .nav-tab:hover {
          color: #f3f4f6;
        }
        .nav-tab.active {
          color: #ef4444;
          border-bottom-color: #ef4444;
        }
        .nav-tab-icon {
          margin-bottom: 4px;
        }
      `}</style>
    </div>
  );
}

export default ObsidianNotes;