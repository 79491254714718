import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ArrowLeft, Edit, Trash, Save, X, FileText, Sidebar, Coffee, Hash, BookOpen, Search, Menu, Settings } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { PlusCircle } from 'lucide-react';
import { toast } from 'react-toastify';
import { CheckCircle, AlertTriangle, Info } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

function ObsidianNoteView() {
  const toastSuccess = (message) => toast.success(message, {
    icon: <CheckCircle className="text-green-500" />,
    className: "bg-gray-800 text-white border border-gray-700"
  });
  
  const toastError = (message) => toast.error(message, {
    icon: <AlertTriangle className="text-red-500" />,
    className: "bg-gray-800 text-white border border-gray-700"
  });
  
  const toastInfo = (message) => toast.info(message, {
    icon: <Info className="text-blue-500" />,
    className: "bg-gray-800 text-white border border-gray-700"
  });
  
  const [note, setNote] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [showTableOfContents, setShowTableOfContents] = useState(true);
  const { noteId } = useParams();
  const navigate = useNavigate();
  const [showTagManager, setShowTagManager] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [availableTags, setAvailableTags] = useState([
    'security', 'network', 'malware', 'web', 'forensics', 
    'encryption', 'privacy', 'authentication', 'vulnerability',
    'firewall', 'linux', 'windows', 'cloud', 'pentest', 'osint'
  ]);

  useEffect(() => {
    const fetchNote = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`/api/notes/${noteId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setNote(data.note);
        setEditedContent(data.note.content);
        setIsAuthenticated(data.is_authenticated);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching note:', error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchNote();
  }, [noteId]);

  const enableEdit = () => setIsEditing(true);

  const cancelEdit = () => {
    setIsEditing(false);
    setEditedContent(note.content);
  };

  const saveContent = async () => {
    try {
      const response = await fetch(`/api/edit/${noteId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: editedContent })
      });
      const data = await response.json();
      if (response.ok) {
        setNote({ ...note, content: editedContent });
        setIsEditing(false);
        toastSuccess('Note saved successfully');
      } else {
        setError(data.error);
        toastError(data.error || 'Failed to save note');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while saving the note');
      toastError('An error occurred while saving the note');
    }
  };

  const deleteNote = async () => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        const response = await fetch(`/api/delete_note/${noteId}`, {
          method: 'POST',
        });
        const data = await response.json();
        if (data.success) {
          navigate('/notes');
          toastSuccess('Note deleted successfully');
        } else {
          setError(data.error);
          toastError(data.error || 'Failed to delete note');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('An error occurred while deleting the note');
        toastError('An error occurred while deleting the note');
      }
    }
  };

  // Tag management functions
  const handleAddTag = async (tagName) => {
    if (!note) return;
    
    // Clone the current tags array or initialize if doesn't exist
    const updatedTags = note.tags ? [...note.tags] : [];
    
    // Don't add if tag already exists
    if (updatedTags.includes(tagName)) {
      toastInfo(`Tag "${tagName}" already exists on this note`);
      return;
    }
    
    // Add the new tag
    updatedTags.push(tagName);
    
    try {
      const response = await fetch(`/api/update_tags/${noteId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tags: updatedTags })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        // Update the note in state
        setNote({
          ...note,
          tags: updatedTags
        });
        
        // Add to available tags if it's a new custom tag
        if (!availableTags.includes(tagName)) {
          setAvailableTags([...availableTags, tagName]);
        }
        
        setNewTag('');
        toastSuccess(`Added tag "${tagName}"`);
      } else {
        toastError(data.error || 'Failed to update tags');
      }
    } catch (error) {
      console.error('Error updating tags:', error);
      toastError('An error occurred while updating tags');
    }
  };

  const handleRemoveTag = async (tagName) => {
    if (!note || !note.tags) return;
    
    // Remove the tag from the array
    const updatedTags = note.tags.filter(tag => tag !== tagName);
    
    try {
      const response = await fetch(`/api/update_tags/${noteId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tags: updatedTags })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        // Update the note in state
        setNote({
          ...note,
          tags: updatedTags
        });
        
        toastInfo(`Removed tag "${tagName}"`);
      } else {
        toastError(data.error || 'Failed to update tags');
      }
    } catch (error) {
      console.error('Error updating tags:', error);
      toastError('An error occurred while updating tags');
    }
  };

  const handleAddCustomTag = (e) => {
    e.preventDefault();
    
    if (!newTag.trim()) return;
    
    // Normalize tag: lowercase, no spaces, alphanumeric + hyphens only
    const normalizedTag = newTag.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
    
    if (normalizedTag) {
      handleAddTag(normalizedTag);
    }
  };

  // Wiki-style link functionality
  const processWikiLinks = (content) => {
    if (!content) return content;
    
    // Replace [[Note Title]] with links
    return content.replace(/\[\[([^\]]+)\]\]/g, (match, noteTitle) => {
      // Here, you'd normally look up the note ID by title
      // For now, we'll just style it as a link with a special class
      return `<span class="wiki-link" data-note-title="${noteTitle}">${noteTitle}</span>`;
    });
  };

  const handleWikiLinkClick = async (noteTitle) => {
    try {
      // Try to find a note with this title
      const response = await fetch(`/api/notes/search?title=${encodeURIComponent(noteTitle)}`);
      const data = await response.json();
      
      if (data.notes && data.notes.length > 0) {
        // Navigate to the first matching note
        navigate(`/notes/${data.notes[0].id}`);
      } else {
        // Offer to create a new note with this title
        if (window.confirm(`Note "${noteTitle}" doesn't exist. Would you like to create it?`)) {
          // Navigate to new note creation with prefilled title
          navigate(`/new_note?title=${encodeURIComponent(noteTitle)}`);
        }
      }
    } catch (error) {
      console.error('Error finding linked note:', error);
      toastError('Error following link');
    }
  };

  // Extract headings for table of contents
  const extractHeadings = (content) => {
    if (!content) return [];
    const headingRegex = /^(#{1,6})\s+(.+)$/gm;
    const headings = [];
    let match;

    while ((match = headingRegex.exec(content)) !== null) {
      const level = match[1].length;
      const text = match[2].trim();
      headings.push({ level, text });
    }
    
    return headings;
  };

  const renderTableOfContents = () => {
    if (!note || !showTableOfContents) return null;
    
    const headings = extractHeadings(note.content);
    if (headings.length === 0) return null;
    
    return (
      <div className="mb-6 p-4 bg-gray-800 rounded-lg border border-gray-700">
        <h3 className="text-lg font-medium mb-2 flex items-center">
          <Hash size={18} className="mr-2" />
          Table of Contents
        </h3>
        <ul className="space-y-1">
          {headings.map((heading, index) => (
            <li 
              key={index} 
              className="text-gray-300 hover:text-gray-100"
              style={{ marginLeft: `${(heading.level - 1) * 16}px` }}
            >
              <a href={`#heading-${index}`} className="hover:underline">
                {heading.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderContent = () => {
    if (!note) return null;
    
    if (isEditing) {
      return (
        <div className="flex flex-col h-full">
          <div className="mb-4 p-2 bg-gray-700/30 rounded text-sm text-gray-300 border border-gray-700">
            <strong>Tip:</strong> Create links to other notes using double brackets: [[Note Title]]
          </div>
          <textarea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            className="w-full flex-grow min-h-[500px] p-4 bg-gray-800 text-gray-200 border border-gray-700 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 font-mono"
          />
        </div>
      );
    }
    
    // Add IDs to headings for TOC linking
    const processedContent = note.content.replace(
      /^(#{1,6})\s+(.+)$/gm, 
      (match, p1, p2, offset, string, groups) => {
        const index = extractHeadings(note.content).findIndex(
          h => h.text === p2.trim() && h.level === p1.length
        );
        return `${p1} ${p2} {#heading-${index}}`;
      }
    );
    
    // Process wiki links before passing to markdown renderer
    const contentWithLinks = processWikiLinks(processedContent);
    
    return (
      <div className="prose prose-invert max-w-none wiki-content">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  style={vscDarkPlus}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            // Handle raw HTML to support our custom wiki links
            span({node, className, children, ...props}) {
              if (className === 'wiki-link') {
                return (
                  <a 
                    href={`/notes/search?q=${encodeURIComponent(props['data-note-title'])}`}
                    className="text-red-400 hover:text-red-300 border-b border-dashed border-red-500/50 hover:border-red-400"
                    onClick={(e) => {
                      e.preventDefault();
                      handleWikiLinkClick(props['data-note-title']);
                    }}
                  >
                    {children}
                  </a>
                );
              }
              return <span className={className} {...props}>{children}</span>;
            }
          }}
        >
          {contentWithLinks}
        </ReactMarkdown>
      </div>
    );
  };

  // Render tag manager component
  const renderTagManager = () => {
    if (!note) return null;
    
    const currentTags = note.tags || [];
    
    return (
      <div className="border-t border-gray-800 pt-4 mt-4">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-xs uppercase font-semibold text-gray-500">Tags</h3>
          <button 
            onClick={() => setShowTagManager(!showTagManager)}
            className="text-xs text-gray-400 hover:text-white"
          >
            {showTagManager ? 'Done' : 'Manage'}
          </button>
        </div>

        {currentTags.length > 0 ? (
          <div className="flex flex-wrap gap-2 mb-3">
            {currentTags.map((tag, index) => (
              <span 
                key={index} 
                className="px-2 py-1 bg-gray-800 text-red-400 text-xs rounded-md flex items-center group"
              >
                <Hash size={12} className="mr-1" />
                {tag}
                {showTagManager && (
                  <button 
                    onClick={() => handleRemoveTag(tag)}
                    className="ml-1 opacity-0 group-hover:opacity-100 transition-opacity text-gray-400 hover:text-red-300"
                  >
                    <X size={12} />
                  </button>
                )}
              </span>
            ))}
          </div>
        ) : (
          <div className="text-sm text-gray-500 italic mb-3">
            No tags yet
          </div>
        )}
        
        {showTagManager && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className="mb-3"
          >
            <form onSubmit={handleAddCustomTag} className="mb-3">
              <div className="flex">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  placeholder="Add custom tag..."
                  className="flex-grow bg-gray-800 border border-gray-700 rounded-l text-sm px-2 py-1 text-white focus:outline-none focus:ring-1 focus:ring-red-500"
                />
                <button 
                  type="submit"
                  className="bg-red-600 hover:bg-red-500 text-white px-2 py-1 rounded-r text-sm transition-colors"
                  disabled={!newTag.trim()}
                >
                  Add
                </button>
              </div>
            </form>
            
            <div className="text-xs font-medium text-gray-500 mb-2">Suggested Tags</div>
            <div className="max-h-40 overflow-y-auto">
              <div className="flex flex-wrap gap-2">
                {availableTags
                  .filter(tag => !currentTags.includes(tag))
                  .slice(0, 15)
                  .map((tag, index) => (
                    <button
                      key={index}
                      onClick={() => handleAddTag(tag)}
                      className="px-2 py-1 bg-gray-800 hover:bg-gray-700 text-gray-400 hover:text-white text-xs rounded-md flex items-center transition-colors"
                    >
                      <PlusCircle size={12} className="mr-1" />
                      {tag}
                    </button>
                  ))}
              </div>
            </div>
          </motion.div>
        )}
      </div>
    );
  };

  // Render sidebar content
  const renderSidebarContent = () => (
    <div className="p-4">
      <Link to="/notes" className="flex items-center text-gray-400 hover:text-gray-200 mb-4">
        <ArrowLeft size={16} className="mr-2" />
        Back to Notes
      </Link>
      
      {/* Additional sidebar content */}
      <div className="border-t border-gray-800 pt-4 mt-4">
        <h3 className="text-xs uppercase font-semibold text-gray-500 mb-2">Actions</h3>
        <ul className="space-y-1">
          <li>
            <button className="w-full text-left flex items-center text-gray-400 hover:text-gray-200 px-2 py-1 rounded hover:bg-gray-800">
              <Search size={16} className="mr-2" />
              <span className="text-sm">Find in note</span>
            </button>
          </li>
          <li>
            <button 
              className="w-full text-left flex items-center text-gray-400 hover:text-gray-200 px-2 py-1 rounded hover:bg-gray-800"
              onClick={() => setShowTagManager(!showTagManager)}
            >
              <Hash size={16} className="mr-2" />
              <span className="text-sm">Tag note</span>
            </button>
          </li>
          <li>
            <button className="w-full text-left flex items-center text-gray-400 hover:text-gray-200 px-2 py-1 rounded hover:bg-gray-800">
              <Coffee size={16} className="mr-2" />
              <span className="text-sm">Add to favorites</span>
            </button>
          </li>
        </ul>
      </div>
      
      {/* Tag manager section */}
      {renderTagManager()}
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-gray-100">
        <div className="flex flex-col items-center">
          <div className="w-12 h-12 border-4 border-red-500 border-t-transparent rounded-full animate-spin"></div>
          <p className="mt-4 text-gray-400">Loading note...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-screen bg-gray-900 text-gray-100 p-4">
        <div className="max-w-3xl mx-auto bg-red-900/30 border border-red-600 rounded-lg p-4">
          <h2 className="text-xl font-bold text-red-400 mb-2">Error</h2>
          <p>{error}</p>
          <Link to="/notes" className="inline-block mt-4 px-4 py-2 bg-gray-800 hover:bg-gray-700 rounded-lg">
            Back to Notes
          </Link>
        </div>
      </div>
    );
  }

  if (!note) {
    return (
      <div className="h-screen bg-gray-900 text-gray-100 p-4">
        <div className="max-w-3xl mx-auto bg-gray-800 rounded-lg p-4">
          <h2 className="text-xl font-bold mb-2">Note not found</h2>
          <p>The requested note could not be found.</p>
          <Link to="/notes" className="inline-block mt-4 px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg">
            Back to Notes
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col bg-gray-900 text-gray-100">
      {/* Top navbar */}
      <div className="flex items-center bg-gray-800 p-2 border-b border-gray-700">
        <button 
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)} 
          className="p-2 rounded hover:bg-gray-700"
          title={sidebarCollapsed ? "Show sidebar" : "Hide sidebar"}
        >
          <Menu size={20} />
        </button>
        
        <div className="flex-grow flex justify-between mx-4">
          <span className="font-medium truncate">
            <FileText size={18} className="inline mr-2" />
            {note.title}
          </span>
          <div className="flex items-center space-x-2">
            {isAuthenticated && (
              <>
                {!isEditing ? (
                  <>
                    <button 
                      onClick={enableEdit} 
                      className="p-2 rounded hover:bg-gray-700"
                      title="Edit note"
                    >
                      <Edit size={18} />
                    </button>
                    <button 
                      onClick={deleteNote} 
                      className="p-2 rounded hover:bg-gray-700 text-red-400 hover:text-red-300"
                      title="Delete note"
                    >
                      <Trash size={18} />
                    </button>
                  </>
                ) : (
                  <>
                    <button 
                      onClick={saveContent} 
                      className="p-2 rounded hover:bg-gray-700 text-green-400 hover:text-green-300"
                      title="Save changes"
                    >
                      <Save size={18} />
                    </button>
                    <button 
                      onClick={cancelEdit} 
                      className="p-2 rounded hover:bg-gray-700 text-red-400 hover:text-red-300"
                      title="Cancel"
                    >
                      <X size={18} />
                    </button>
                  </>
                )}
              </>
            )}
            <button 
              onClick={() => setShowTableOfContents(!showTableOfContents)} 
              className={`p-2 rounded hover:bg-gray-700 ${showTableOfContents ? 'text-blue-400' : 'text-gray-400'}`}
              title="Toggle table of contents"
            >
              <BookOpen size={18} />
            </button>
          </div>
        </div>
      </div>
      
      <div className="flex flex-1 overflow-hidden">
        {/* Left sidebar */}
        {!sidebarCollapsed && (
          <div className="w-60 bg-gray-900 border-r border-gray-700 overflow-y-auto">
            {renderSidebarContent()}
          </div>
        )}
        
        {/* Main content */}
        <div className="flex-1 overflow-auto p-4">
          <div className="max-w-3xl mx-auto">
            {renderTableOfContents()}
            
            <div className="bg-gray-800 rounded-lg border border-gray-700 p-6">
              <h1 className="text-2xl font-bold mb-6">{note.title}</h1>
              {renderContent()}
            </div>
            
            <div className="mt-4 text-right text-gray-500 text-sm">
              Last edited: {new Date().toLocaleDateString()}
            </div>
          </div>
        </div>
      </div>
      
      {/* CSS for wiki links */}
      <style jsx>{`
        .wiki-content a {
          transition: all 0.2s;
        }

        .wiki-link {
          color: #f87171;
          border-bottom: 1px dashed rgba(248, 113, 113, 0.5);
          cursor: pointer;
        }

        .wiki-link:hover {
          color: #ef4444;
          border-color: #ef4444;
        }
      `}</style>
    </div>
  );
}

export default ObsidianNoteView;