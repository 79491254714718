import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RefreshCw, Lock, User } from 'lucide-react';

// Styled components
const LoginContainer = styled.div`
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background-color: #1e1e2e;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(70, 130, 180, 0.3);
  color: #d1d5db;
  font-family: 'Inter', 'Segoe UI', sans-serif;
`;

const Title = styled.h1`
  color: #d1d5db;
  border-bottom: 2px solid #4682b4;
  padding-bottom: 10px;
  margin-top: 0;
  font-size: 1.5rem;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 6px;
  font-size: 0.875rem;
  color: #9ca3af;
`;

const Input = styled.input`
  background-color: #2a2a3c;
  color: #d1d5db;
  border: 1px solid #4b5563;
  width: 100%;
  padding: 10px 10px 10px ${props => props.hasIcon ? '36px' : '10px'};
  border-radius: 6px;
  font-family: inherit;
  font-size: 14px;
  box-sizing: border-box;
  outline: none;
  
  &:focus {
    border-color: #4682b4;
    box-shadow: 0 0 0 2px rgba(70, 130, 180, 0.2);
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 34px;
  left: 10px;
  color: #9ca3af;
`;

const Button = styled.button`
  background-color: #4682b4;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  cursor: pointer;
  font-family: inherit;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #3a6d99;
  }
  
  &:disabled {
    background-color: #4682b4;
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const CaptchaContainer = styled.div`
  margin-bottom: 16px;
  color: #d1d5db;
  display: flex;
  flex-direction: column;
`;

const CaptchaInput = styled.div`
  display: flex;
`;

const CaptchaRefreshButton = styled.button`
  background-color: #2a2a3c;
  color: #9ca3af;
  border: 1px solid #4b5563;
  border-left: none;
  border-radius: 0 6px 6px 0;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #374151;
    color: #d1d5db;
  }
`;

const CaptchaField = styled(Input)`
  border-radius: 6px 0 0 6px;
`;

const Message = styled.div`
  margin-top: 16px;
  padding: 10px;
  border-radius: 6px;
  color: ${props => props.error ? '#ef4444' : '#10b981'};
  background-color: ${props => props.error ? 'rgba(239, 68, 68, 0.1)' : 'rgba(16, 185, 129, 0.1)'};
  border: 1px solid ${props => props.error ? 'rgba(239, 68, 68, 0.2)' : 'rgba(16, 185, 129, 0.2)'};
`;

const Spinner = styled.div`
  margin-right: 8px;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captchaAnswer, setCaptchaAnswer] = useState('');
  const [captchaQuestion, setCaptchaQuestion] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Determine the API base URL based on environment
  // In production (or deployed to Heroku), use relative URLs
  // In development, use the full localhost URL
  const API_BASE_URL = process.env.NODE_ENV === 'production' 
    ? '' // Empty string for relative URLs in production
    : 'http://localhost:5000'; // Local backend server in development

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      console.log("Fetching captcha...");
      const response = await fetch(`${API_BASE_URL}/api/captcha`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json'
        }
      });
      console.log("Captcha response status:", response.status);
      
      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}`);
      }
      
      const data = await response.json();
      console.log("Captcha data:", data);
      setCaptchaQuestion(data.question);
    } catch (error) {
      console.error('Error fetching captcha:', error);
      setMessage('Error loading captcha. Please try again later.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${API_BASE_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          username,
          password,
          captcha: captchaAnswer,
        }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        onLogin(true);
        setMessage(data.message || 'Login successful');
      } else {
        setMessage(data.error || 'Login failed');
        fetchCaptcha(); // Refresh captcha on failed attempt
        setCaptchaAnswer('');
      }
    } catch (error) {
      console.error('Login error:', error);
      setMessage('An error occurred during login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginContainer>
      <Title>Login</Title>
      <form onSubmit={handleSubmit}>
        <InputGroup>
          <InputLabel htmlFor="username">Username</InputLabel>
          <IconContainer>
            <User size={16} />
          </IconContainer>
          <Input
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your username"
            required
            hasIcon
          />
        </InputGroup>
        
        <InputGroup>
          <InputLabel htmlFor="password">Password</InputLabel>
          <IconContainer>
            <Lock size={16} />
          </IconContainer>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
            hasIcon
          />
        </InputGroup>
        
        <CaptchaContainer>
          <InputLabel htmlFor="captcha">Captcha: {captchaQuestion || "Loading..."}</InputLabel>
          <CaptchaInput>
            <CaptchaField
              id="captcha"
              type="text"
              value={captchaAnswer}
              onChange={(e) => setCaptchaAnswer(e.target.value)}
              placeholder="Enter the answer"
              required
              disabled={!captchaQuestion}
            />
            <CaptchaRefreshButton 
              type="button" 
              onClick={fetchCaptcha}
              title="Get new captcha"
            >
              <RefreshCw size={16} />
            </CaptchaRefreshButton>
          </CaptchaInput>
        </CaptchaContainer>
        
        <Button type="submit" disabled={isLoading || !captchaQuestion}>
          {isLoading ? (
            <>
              <Spinner>
                <RefreshCw size={16} />
              </Spinner>
              Logging in...
            </>
          ) : (
            'Login'
          )}
        </Button>
      </form>
      
      {message && (
        <Message error={message.includes('Error') || message.includes('failed') || message.includes('Invalid')}>
          {message}
        </Message>
      )}
    </LoginContainer>
  );
}

export default Login;