import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const VARIANTS = [
  ["classic", "Classic"],
  ["dog", "Dog"],
  ["tora", "Tora"],
  ["maia", "Maia"],
];

export default function Header({ onVariantChange, onKuroNekoToggle, onNekoToggle, nekoEnabled }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [sliderStyles, setSliderStyles] = useState({ width: 0, left: 0, opacity: 0 });
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showNekoMenu, setShowNekoMenu] = useState(false);
  const navRef = useRef(null);
  const navigate = useNavigate();

  const navItems = [
    { name: 'ABOUT', path: '/' },
    { name: 'NOTES', path: '/notes' },
    { name: 'PROJECTS', path: '/projects' },
    { name: 'BLOG', path: '/blog' },
    { name: 'FRIENDS', path: '#', isNeko: true}
  ];

  useEffect(() => {
    if (navRef.current) {
      const firstButton = navRef.current.children[0];
      setSliderStyles({
        width: firstButton.offsetWidth,
        left: firstButton.offsetLeft,
        opacity: 0,
      });
    }
  }, []);

  useEffect(() => {
    if (navRef.current && hoveredIndex !== null) {
      const button = navRef.current.children[hoveredIndex];
      setSliderStyles({
        width: button.offsetWidth,
        left: button.offsetLeft,
        opacity: 1,
      });
    } else if (hoveredIndex === null) {
      setSliderStyles(prev => ({ ...prev, opacity: 0 }));
    }
  }, [hoveredIndex]);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== 'undefined') {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
        setLastScrollY(currentScrollY);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showNekoMenu && !event.target.closest('.neko-menu-container')) {
        setShowNekoMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNekoMenu]);

  const handleNavigation = (path, isNeko) => {
    if (isNeko) {
      if (!nekoEnabled) {
        // First click enables Neko
        onNekoToggle(true);
      }
      setShowNekoMenu(!showNekoMenu);
    } else {
      navigate(path);
      setShowNekoMenu(false);
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.header
          className="p-2 bg-black shadow-md sticky top-0 w-full z-50"
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          exit={{ y: -100 }}
          transition={{ duration: 0.3 }}
        >
          <nav ref={navRef} className="flex justify-center items-center relative">
            {navItems.map((item, index) => (
              <div key={item.name} className="relative">
                <button
                  className={`
                    px-3 py-2 text-sm font-medium relative z-10
                    transition-colors duration-300
                    ${index === hoveredIndex ? 'text-white' : 'text-gray-400'}
                    hover:text-gray-200
                  `}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => handleNavigation(item.path, item.isNeko)}
                >
                  {item.name}
                </button>

                {item.isNeko && showNekoMenu && nekoEnabled && (
                  <motion.div
                    className="neko-menu-container absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                  >
                    <div className="py-1">
                      {VARIANTS.map(([value, label]) => (
                        <button
                          key={value}
                          className="block w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 text-left"
                          onClick={() => {
                            onVariantChange?.(value);
                            setShowNekoMenu(false);
                          }}
                        >
                          {label}
                        </button>
                      ))}
                      <div className="border-t border-gray-700 my-1"></div>
                      <button
                        className="block w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 text-left border-t border-gray-700"
                        onClick={() => {
                          onNekoToggle(false);
                          setShowNekoMenu(false);
                        }}
                      >
                        Disable Friend
                      </button>
                    </div>
                  </motion.div>
                )}
              </div>
            ))}
            <motion.div
              className="absolute h-8 bg-gray-700 rounded-full bottom-1"
              initial={false}
              animate={sliderStyles}
              transition={{
                type: "spring",
                stiffness: 500,
                damping: 30,
                opacity: { duration: 0.2 }
              }}
            />
          </nav>
        </motion.header>
      )}
    </AnimatePresence>
  );
}
