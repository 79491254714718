import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Existing components
import Header from './Components/Header';
import Hero from './Components/Hero';
import About from './Components/About';
import Experience from './Components/Experience';
import Footer from './Components/Footer';
import Projects from './Components/Projects';
import Logout from './Components/Logout';
import Terminal from './Components/Terminal';
import Oneko from './Components/Oneko';
// Import the updated GraphView component
import GraphView from './Components/GraphView';
// Import SampleData correctly with named export
import { generateSampleData } from './Components/SampleData';
// New Obsidian-style components
import ObsidianNotes from './Components/ObsidianNotes';
import ObsidianNoteView from './Components/ObsidianNoteView';
import NewNote from './Components/NewNote';
import AIBlog from './Components/AIBlog';

function App() {
  const [nekoEnabled, setNekoEnabled] = useState(false);
  const [nekoVariant, setNekoVariant] = useState(() => {
    return localStorage.getItem('oneko:variant') || 'classic';
  });
  const [kuroNeko, setKuroNeko] = useState(() => {
    return JSON.parse(localStorage.getItem('oneko:kuroneko') || 'false');
  });
  
  // We'll use state to safely store the sample data
  const [sampleData, setSampleData] = useState({ notes: [], folders: [], favorites: [] });
  
  // Safely load sample data on component mount
  useEffect(() => {
    try {
      const data = generateSampleData();
      setSampleData(data);
    } catch (error) {
      console.error("Error generating sample data:", error);
      // Fallback to empty data
      setSampleData({ notes: [], folders: [], favorites: [] });
    }
  }, []);
  
  return (
    <Router>
      <AnimatePresence>
        <div className="App bg-black min-h-screen text-white">
          {nekoEnabled && <Oneko variant={nekoVariant} kuroNeko={kuroNeko} />}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Header
            onVariantChange={setNekoVariant}
            onKuroNekoToggle={setKuroNeko}
            onNekoToggle={setNekoEnabled}
            nekoEnabled={nekoEnabled}
          />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <About />
                  <Experience />
                </>
              }
            />
            {/* Use new Obsidian-style Notes component */}
            <Route path="/notes" element={<ObsidianNotes />} />
            <Route path="/notes/graph" element={<ObsidianNotes initialViewMode="graph" />} />
            <Route path="/notes/favorites" element={<ObsidianNotes initialViewMode="favorites" />} />
            <Route path="/notes/recent" element={<ObsidianNotes initialViewMode="recent" />} />
            
           
            {/* Use new Obsidian-style NoteView component */}
            <Route path="/notes/:noteId" element={<ObsidianNoteView />} />
           
            <Route path="/projects" element={<Projects />} />
            <Route path="/logout" element={<Logout />} />
            
            {/* Change /graph to use ObsidianNotes with graph mode */}
            <Route path="/graph" element={<ObsidianNotes initialViewMode="graph" />} />
       
           
            {/* Use new NewNote component instead of AddNote */}
            <Route path="/new_note" element={<NewNote />} />
            <Route path="/blog" element={<AIBlog />} /> 
           
            {/* Uncomment if needed */}
            {/* <Route path="/cli" element={<Terminal/>} /> */}
          </Routes>
          <Footer />
        </div>
      </AnimatePresence>
    </Router>
  );
}
export default App;