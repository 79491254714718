import React, { useState, useEffect } from 'react';
import { FaShieldAlt, FaRobot, FaUserSecret, FaCode, FaBrain, FaChartLine, FaExclamationTriangle, FaGraduationCap, FaUsers } from 'react-icons/fa';

// Custom hook for animation on scroll
const useScrollAnimation = (threshold = 0.1) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false); // Start with elements hidden

  useEffect(() => {
    if (!ref) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
          observer.disconnect();
        }
      },
      { threshold }
    );

    observer.observe(ref);
    return () => observer.disconnect();
  }, [ref, threshold]);

  return [setRef, visible];
};

// Animated typography component
const AnimatedHeading = ({ children, delay = 0, className = "", direction = "up" }) => {
  const [ref, visible] = useScrollAnimation();
  
  const getTransform = () => {
    if (!visible) {
      switch(direction) {
        case "left": return "translate-x-[-50px] opacity-0";
        case "right": return "translate-x-[50px] opacity-0";
        case "up": return "translate-y-[50px] opacity-0";
        case "down": return "translate-y-[-50px] opacity-0";
        default: return "translate-y-[50px] opacity-0";
      }
    }
    return "translate-y-0 translate-x-0 opacity-100";
  };
  
  return (
    <h2 
      ref={ref}
      className={`text-2xl md:text-3xl font-bold mb-4 ${className} transition-all duration-1000 ease-out transform ${
        visible ? "translate-y-0 translate-x-0 opacity-100" : getTransform()
      }`}
      style={{ transitionDelay: `${delay}ms` }}
    >
      {children}
    </h2>
  );
};

// Animated paragraph component
const AnimatedParagraph = ({ children, delay = 0, direction = "left" }) => {
  const [ref, visible] = useScrollAnimation();
  
  const getTransform = () => {
    if (!visible) {
      switch(direction) {
        case "left": return "opacity-0 translate-x-[-30px]";
        case "right": return "opacity-0 translate-x-[30px]";
        case "up": return "opacity-0 translate-y-[30px]";
        case "down": return "opacity-0 translate-y-[-30px]";
        default: return "opacity-0 translate-x-[-30px]";
      }
    }
    return "opacity-100 translate-x-0 translate-y-0";
  };
  
  return (
    <p 
      ref={ref}
      className={`mb-4 text-gray-300 transition-all duration-700 ease-out transform ${getTransform()}`}
      style={{ transitionDelay: `${delay}ms` }}
    >
      {children}
    </p>
  );
};

// Feature card component
const FeatureCard = ({ icon: Icon, title, description, delay = 0, direction = "up" }) => {
  const [ref, visible] = useScrollAnimation(0.2);
  
  const getTransform = () => {
    if (!visible) {
      switch(direction) {
        case "left": return "translate-x-[-50px] opacity-0 scale-95";
        case "right": return "translate-x-[50px] opacity-0 scale-95";
        case "up": return "translate-y-[50px] opacity-0 scale-95";
        case "down": return "translate-y-[-50px] opacity-0 scale-95";
        default: return "translate-y-[50px] opacity-0 scale-95";
      }
    }
    return "translate-y-0 translate-x-0 opacity-100 scale-100";
  };
  
  return (
    <div 
      ref={ref}
      className={`bg-gray-900 border border-gray-800 rounded-lg p-6 transition-all duration-700 ease-out transform ${
        getTransform()
      }`}
      style={{ transitionDelay: `${delay}ms` }}
    >
      <div className="flex items-center mb-4">
        <div className="w-12 h-12 rounded-full bg-gradient-to-br from-red-500 to-orange-500 flex items-center justify-center text-white">
          <Icon size={24} />
        </div>
        <h3 className="text-xl font-bold ml-4 text-white">{title}</h3>
      </div>
      <p className="text-gray-400">{description}</p>
    </div>
  );
};

// Statistics component
const StatCounter = ({ value, label, prefix = "", suffix = "", delay = 0, source = "" }) => {
  const [ref, visible] = useScrollAnimation(0.1);
  const [count, setCount] = useState(0);
  
  useEffect(() => {
    if (!visible) return;
    
    let start = 0;
    const end = parseInt(value);
    const duration = 1500;
    const increment = end / (duration / 16);
    
    const timer = setInterval(() => {
      start += increment;
      if (start > end) start = end;
      setCount(Math.floor(start));
      if (start === end) clearInterval(timer);
    }, 16);
    
    return () => clearInterval(timer);
  }, [visible, value]);
  
  return (
    <div 
      ref={ref}
      className={`text-center transition-all duration-700 ease-out transform ${
        visible ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
      }`}
      style={{ transitionDelay: `${delay}ms` }}
    >
      <div className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">
        {prefix}{visible ? count : 0}{suffix}
      </div>
      <div className="text-gray-400 text-sm mt-1">{label}</div>
      {source && <div className="text-gray-500 text-xs mt-1 italic">{source}</div>}
    </div>
  );
};

// Scrolling text banner component
const ScrollingBanner = ({ words }) => {
  return (
    <div className="w-full py-4 bg-gray-900 border-y border-gray-800 overflow-hidden my-12">
      <div className="flex animate-marquee whitespace-nowrap">
        {[...words, ...words, ...words].map((word, index) => (
          <div key={index} className="mx-4 text-lg font-semibold text-gray-500">
            <span className="text-red-500">#</span>{word}
          </div>
        ))}
      </div>
    </div>
  );
};

// Main blog component
const AIBlog = () => {
  const [ref, visible] = useScrollAnimation();
  
  return (
    <div className="min-h-screen bg-black text-white relative select-none" style={{ userSelect: 'none' }}>
      {/* Dotted background pattern */}
      <div 
        className="absolute inset-0 pointer-events-none"
        style={{
          backgroundImage: `radial-gradient(#ffffff 1px, transparent 1px), radial-gradient(#ffffff 1px, transparent 1px)`,
          backgroundSize: '50px 50px',
          backgroundPosition: '0 0, 25px 25px',
          opacity: 0.05,
          maskImage: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.1) 100%)',
          WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.1) 100%)',
        }}
      />
      
      {/* Hero section - Attention */}
      <section className="relative px-4 py-10 md:py-16 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-red-900/20 to-transparent opacity-50"></div>
        <div className="container mx-auto relative">
          <div 
            ref={ref}
            className={`max-w-3xl mx-auto text-center transition-all duration-1000 ${
              visible ? "opacity-100 transform-none" : "opacity-0 translate-y-8"
            }`}
          >
            <div className="inline-block mb-2">
              <div className="flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-gradient-to-br from-red-500 to-orange-500">
                <FaShieldAlt size={32} className="text-white" />
              </div>
            </div>
            <h1 className="text-4xl md:text-5xl font-bold mb-3">
              Embracing Generative AI
              <span className="block mt-1 bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">
                in
              </span>
              <span className="block mt-1 bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">
                Cybersecurity
              </span>
            </h1>
            <p className="text-lg text-gray-300 mb-4">
              Have you ever wondered what cybersecurity will look like five years from now? The future is AI-powered, and your career depends on embracing it today.
            </p>
            <button className="px-8 py-3 bg-gradient-to-r from-red-500 to-orange-500 rounded-full font-bold transition-all hover:shadow-lg hover:shadow-red-500/30 hover:scale-105">
              Discover How
            </button>
          </div>
        </div>
      </section>
      
      {/* Current landscape section - Interest/Credibility */}
      <section className="px-4 py-8 bg-black/50 backdrop-blur-sm">
        <div className="container mx-auto">
          <AnimatedHeading direction="left">
            The Current <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Cybersecurity Landscape</span>
          </AnimatedHeading>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <AnimatedParagraph delay={100} direction="left">
                As a cybersecurity student closely following the integration of AI technologies in our field, I've witnessed how quickly the security landscape is changing. Traditional methods of threat detection and response are becoming obsolete as attacks grow more sophisticated.
              </AnimatedParagraph>
              <AnimatedParagraph delay={200} direction="left">
                The statistics speak for themselves - organizations that have incorporated AI into their security operations have experienced a <span className="font-bold text-red-400">28% reduction in breach detection time</span> and a <span className="font-bold text-red-400">22% decrease in remediation costs</span>. These aren't just numbers—they represent a fundamental shift in how cybersecurity is practiced.
              </AnimatedParagraph>
            </div>
            
            <div className="grid grid-cols-2 gap-4">
              <StatCounter 
                value="28" 
                suffix="%" 
                label="Reduction in Breach Detection Time" 
                delay={150} 
                source="IBM Cost of Data Breach Report 2023"
              />
              <StatCounter 
                value="22" 
                suffix="%" 
                label="Decrease in Remediation Costs" 
                delay={300}
                source="IBM Security 2023" 
              />
              <StatCounter 
                value="30" 
                suffix="%" 
                label="Of Security Operations To Be Autonomous by 2030" 
                delay={450}
                source="Gartner Research 2023" 
              />
              <StatCounter 
                value="11" 
                suffix="%" 
                label="Higher Salaries for AI-Skilled Professionals" 
                delay={600}
                source="Burning Glass Technologies" 
              />
            </div>
          </div>
        </div>
      </section>
      
      {/* The power of Gen AI section - Desire */}
      <section className="px-4 py-12 mt-16">
        <div className="container mx-auto">
          <AnimatedHeading direction="right">
            The Power of <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Generative AI</span> in Cybersecurity
          </AnimatedHeading>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
            <FeatureCard 
              icon={FaBrain}
              title="Adaptive Threat Analysis"
              description="AI uses predictive modeling to identify potential threats before they match known patterns, catching zero-day exploits that would slip through conventional defenses."
              delay={100}
              direction="left"
            />
            <FeatureCard 
              icon={FaCode}
              title="'Vibe Coding' Security Solutions"
              description="Describe a security vulnerability to an AI system and receive a fully functional detection script in seconds—no expert programming knowledge required."
              delay={300}
              direction="up"
            />
            <FeatureCard 
              icon={FaUserSecret}
              title="Countering Advanced Social Engineering"
              description="AI systems can detect subtle inconsistencies in deepfake videos or synthetic voices that human observers might miss, creating critical defenses against emerging threats."
              delay={500}
              direction="right"
            />
          </div>
          
          {/* What if section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-16">
            <div className="bg-gradient-to-br from-gray-900 to-black border border-gray-800 rounded-lg p-8 relative overflow-hidden">
              <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-green-500/20 to-transparent rounded-bl-full"></div>
              <h3 className="text-2xl font-bold mb-4 flex items-center">
                <FaChartLine className="mr-3 text-green-500" size={24} />
                What If You Embrace AI Skills?
              </h3>
              <AnimatedParagraph delay={100}>
                Imagine walking into a job interview and being able to demonstrate how you used generative AI to develop a custom threat detection system during your studies. Your potential employer sees not just a cybersecurity graduate, but an innovator.
              </AnimatedParagraph>
              <AnimatedParagraph delay={200}>
                Security professionals who master generative AI are commanding salaries <span className="font-bold text-green-500">11% higher</span> than their counterparts who rely solely on traditional methods. They're the ones leading the most important security initiatives.
              </AnimatedParagraph>
            </div>
            
            <div className="bg-gradient-to-br from-gray-900 to-black border border-gray-800 rounded-lg p-8 relative overflow-hidden">
              <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-red-500/20 to-transparent rounded-bl-full"></div>
              <h3 className="text-2xl font-bold mb-4 flex items-center">
                <FaExclamationTriangle className="mr-3 text-red-500" size={24} />
                What If You Don't?
              </h3>
              <AnimatedParagraph delay={100}>
                What if you graduate with only traditional cybersecurity skills? As AI becomes integrated into every aspect of security operations, professionals who can't effectively work with these systems will find themselves increasingly limited.
              </AnimatedParagraph>
              <AnimatedParagraph delay={200}>
                Consider this sobering reality: experts predict that by 2030, <span className="font-bold text-red-500">30% of security operations</span> in large enterprises will adopt an autonomous security operations model. Without AI proficiency, your ability to manage and work with these systems will be severely limited.
              </AnimatedParagraph>
            </div>
          </div>
        </div>
      </section>
      
      {/* Ethical considerations section */}
      <section className="px-4 py-16">
        <div className="container mx-auto">
          <div className="max-w-3xl mx-auto text-center">
            <AnimatedHeading direction="left" className="text-center">
              Addressing <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Ethical Concerns</span>
            </AnimatedHeading>
            
            <div>
              <AnimatedParagraph delay={100} direction="up" className="text-center">
                Some of you might be hesitant about embracing AI in cybersecurity due to legitimate ethical concerns. After all, we've seen how AI can be misused—from generating malicious code to creating convincing deepfakes used in fraud.
              </AnimatedParagraph>
              <AnimatedParagraph delay={200} direction="up" className="text-center">
                These concerns are valid, but they actually highlight why ethical cybersecurity professionals need to master these technologies. By understanding generative AI, you'll be positioned to establish ethical frameworks for its use, ensuring that security tools are developed and deployed responsibly.
              </AnimatedParagraph>
              <AnimatedParagraph delay={300} direction="up" className="text-center">
                The ethical use of AI in cybersecurity isn't just about avoiding harm—it's about actively creating systems that protect privacy, ensure fairness, and maintain human oversight of critical security decisions.
              </AnimatedParagraph>
            </div>
          </div>
        </div>
      </section>
      
      {/* Call to action section - Action */}
      <section className="px-4 py-20 relative">
        <div 
          className="absolute inset-0 bg-gradient-to-b from-transparent to-red-900/20 opacity-50"
          style={{
            maskImage: 'linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 100%)',
            WebkitMaskImage: 'linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 100%)',
          }}
        ></div>
        
        <div className="container mx-auto relative">
          <div className="max-w-3xl mx-auto text-center">
            <AnimatedHeading className="text-center" direction="up">
              Securing Your <span className="bg-gradient-to-r from-red-400 to-red-600 text-transparent bg-clip-text">Future</span> in Cybersecurity
            </AnimatedHeading>
            
            <AnimatedParagraph delay={100} direction="right">
              The integration of generative AI into cybersecurity isn't just inevitable—it's already happening. The question isn't whether to embrace these technologies, but how quickly you can develop the skills to leverage them effectively.
            </AnimatedParagraph>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-12">
              <div className="bg-gradient-to-br from-gray-900 to-black border border-gray-800 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <FaGraduationCap className="text-red-500" size={24} />
                  <h3 className="text-xl font-bold ml-3">Start Learning Today</h3>
                </div>
                <p className="text-gray-400">
                  Incorporate AI tools into your current security projects. Start experimenting with platforms like OpenAI's GPT models or Altair AI Studio.
                </p>
              </div>
              
              <div className="bg-gradient-to-br from-gray-900 to-black border border-gray-800 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <FaUsers className="text-red-500" size={24} />
                  <h3 className="text-xl font-bold ml-3">Join the Community</h3>
                </div>
                <p className="text-gray-400">
                  Connect with online communities where security professionals are discussing and sharing AI implementations in security contexts.
                </p>
              </div>
            </div>
            
            <div 
              className="mt-12 bg-gradient-to-r from-red-900/20 to-orange-900/20 border border-red-800/30 rounded-lg p-8"
            >
              <AnimatedParagraph delay={300}>
                "The future of cybersecurity belongs to those who can harness the power of generative AI while maintaining the critical thinking and ethical judgment that only humans can provide. Will you be among them?"
              </AnimatedParagraph>
              
              <button className="mt-6 px-8 py-3 bg-gradient-to-r from-red-500 to-orange-500 rounded-full font-bold transition-all hover:shadow-lg hover:shadow-red-500/30 hover:scale-105">
                Begin Your AI Journey
              </button>
            </div>
          </div>
        </div>
      </section>
            
      {/* Floating animated gradient orb */}
      <div className="fixed -bottom-10 -right-10 w-40 h-40 rounded-full bg-gradient-to-r from-red-500/30 to-orange-500/30 blur-3xl animate-pulse pointer-events-none"></div>
      <div className="fixed -top-20 -left-20 w-60 h-60 rounded-full bg-gradient-to-r from-red-500/10 to-red-800/10 blur-3xl animate-pulse pointer-events-none"></div>
    </div>
  );
};

export default AIBlog;