import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ForceGraph2D from 'react-force-graph-2d';
import ForceGraph3D from 'react-force-graph-3d';
import SpriteText from 'three-spritetext';
import { motion, AnimatePresence } from 'framer-motion';
import * as d3 from 'd3';
import { 
  ZoomIn, ZoomOut, Minimize, Search, Filter, 
  X, Sliders, Info, Hash, Folder, Heart, 
  Plus, Minus, CheckSquare, Square, FileText, 
  Mouse, ArrowLeft, PanelRight, Book,
  RefreshCw, Layers, Compass, Box,
  Hexagon, Target, Zap, Download, Link, Feather,
  Edit, Trash2, MoreHorizontal, Copy, ExternalLink,
  Save, Scissors, AlertTriangle, Edit3, Eye, Wrench
} from 'lucide-react';

// Utility function to extract links from note content
const extractLinks = (notes) => {
  const links = [];
  const titleToId = {};
  
  // Create map of titles to IDs
  notes.forEach(note => {
    titleToId[note.title.toLowerCase()] = note.id;
  });
  
  // Extract wiki-style links [[Note Title]]
  notes.forEach(note => {
    if (!note.content) return;
    
    // Find wiki links: [[Note Title]]
    const wikiLinkRegex = /\[\[([^\]]+)\]\]/g;
    let match;
    
    while ((match = wikiLinkRegex.exec(note.content)) !== null) {
      const linkedTitle = match[1].trim().toLowerCase();
      if (titleToId[linkedTitle]) {
        links.push({
          source: note.id,
          target: titleToId[linkedTitle],
          type: 'wiki',
          value: 3,
          highlighted: false,
          id: `${note.id}-${titleToId[linkedTitle]}-wiki` // Add unique ID for each link
        });
      }
    }
    
    // Also check for mentions of other note titles
    notes.forEach(otherNote => {
      if (note.id !== otherNote.id && otherNote.title && 
          note.content.toLowerCase().includes(otherNote.title.toLowerCase()) &&
          !links.some(link => 
            link.source === note.id && 
            link.target === otherNote.id && 
            link.type === 'wiki')) {
        
        links.push({
          source: note.id,
          target: otherNote.id,
          type: 'mention',
          value: 1,
          highlighted: false,
          id: `${note.id}-${otherNote.id}-mention` // Add unique ID for each link
        });
      }
    });
  });
  
  return links;
};

// Color palette
const COLORS = {
  background: '#0f172a', // slate-900
  node: {
    default: '#64748b', // slate-500
    highlighted: '#f43f5e', // rose-500
    selected: '#ec4899', // pink-500
    text: '#f8fafc', // slate-50
    favorite: '#eab308' // yellow-500
  },
  link: {
    wiki: '#f43f5e', // rose-500
    mention: '#3b82f6', // blue-500
    folder: '#64748b', // slate-500
    highlighted: '#10b981', // emerald-500
    custom: '#a855f7' // purple-500 for custom user-created links
  },
  ui: {
    panel: '#1e293b', // slate-800
    hover: '#334155', // slate-700
    accent: '#f43f5e', // rose-500
    text: {
      primary: '#f8fafc', // slate-50
      secondary: '#94a3b8' // slate-400
    }
  }
};

// Graph themes
const THEMES = {
  dark: {
    background: '#0f172a',
    panel: '#1e293b',
    text: '#f8fafc'
  },
  light: {
    background: '#f8fafc',
    panel: '#e2e8f0',
    text: '#0f172a'
  },
  nebula: {
    background: '#0f0f1e',
    panel: '#1e1e3b',
    text: '#f8f8ff'
  },
  forest: {
    background: '#0f1a0f',
    panel: '#1e2e1e',
    text: '#f0fff0'
  },
  cyber: {
    background: '#050b18',
    panel: '#0c1524',
    text: '#00ffcc'
  }
};

// Link types
const LINK_TYPES = [
  { id: 'wiki', name: 'Wiki Link', color: COLORS.link.wiki, value: 3 },
  { id: 'mention', name: 'Mention', color: COLORS.link.mention, value: 1 },
  { id: 'reference', name: 'Reference', color: COLORS.link.custom, value: 2 },
  { id: 'related', name: 'Related', color: '#8b5cf6', value: 2 },
  { id: 'depends', name: 'Depends On', color: '#06b6d4', value: 2 },
  { id: 'inspires', name: 'Inspires', color: '#fb923c', value: 2 }
];

const GraphView = ({ 
  notes = [], 
  folders = [], 
  favorites = [],
  onNavigateToNote = null,
  isDemoMode = false,
  onCreateNote = null,
  onUpdateNote = null,
  onBackToExplorer = null,
  isMainView = false, // New prop to determine if this is the main view
  onDeleteNote = null // New prop for note deletion handler
}) => {
  const graph2DRef = useRef(null);
  const graph3DRef = useRef(null);
  const navigate = useNavigate();
  
  // State
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [filteredGraphData, setFilteredGraphData] = useState({ nodes: [], links: [] });
  const [selectedNode, setSelectedNode] = useState(null);
  const [highlightedLinks, setHighlightedLinks] = useState(new Set());
  const [highlightedNodes, setHighlightedNodes] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchMode, setSearchMode] = useState('title'); // 'title', 'content', 'tags'
  const [showSearch, setShowSearch] = useState(false);
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [showSettingsPanel, setShowSettingsPanel] = useState(false);
  const [showNodePanel, setShowNodePanel] = useState(true);
  const [is3D, setIs3D] = useState(false);
  const [visibleRightPanel, setVisibleRightPanel] = useState(null); // 'info', 'settings', 'filter', null
  const [isCreatingLink, setIsCreatingLink] = useState(false);
  const [linkSourceNode, setLinkSourceNode] = useState(null);
  const [linkType, setLinkType] = useState('wiki'); // Default link type
  const [theme, setTheme] = useState('cyber'); // Changed default theme to cyber
  const [layout, setLayout] = useState('force'); // 'force', 'radial', 'circle', 'grid'
  const [filters, setFilters] = useState({
    showFavorites: true,
    showAllNotes: true,
    selectedFolders: {},
    selectedTags: {},
    minConnections: 0,
    maxConnections: 100
  });
  
  const [settings, setSettings] = useState({
    nodeSize: 1.8, // Increased node size for better visibility
    linkDistance: 220, // Significantly increased link distance for better spacing
    chargeStrength: -450, // Made charge strength more negative for stronger repulsion
    showLabels: true,
    showTagsOnNodes: true,
    labelScale: 1.4, // Increased label scale for better readability
    particlesPerLink: 3,
    particleSpeed: 0.006,
    centerForce: 0.15, // Reduced center force to allow more spread
    linkWidth: 1.8, // Maintained link width
    dagMode: null, // 'lr', 'td', etc. for hierarchical layout
    showArrows: true,
    nodeSpacing: 12 // Added new parameter for node spacing
  });
  
  // New state for graph editing
  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [isCreatingNode, setIsCreatingNode] = useState(false);
  const [newNodeData, setNewNodeData] = useState({ title: '', content: '', folder_id: null, tags: [] });
  const [confirmDeleteNode, setConfirmDeleteNode] = useState(null);
  const [confirmDeleteLink, setConfirmDeleteLink] = useState(null);
  const [showSidebarControls, setShowSidebarControls] = useState(true);
  const [editTab, setEditTab] = useState('nodes'); // 'nodes', 'links', 'advanced'
  
  const [isLoading, setIsLoading] = useState(true);
  const [animateNodes, setAnimateNodes] = useState(true); // New state for node animation
  
  // Calculate color for folders
  const getFolderColor = useCallback((folderId) => {
    if (!folderId) return COLORS.node.default;
    
    const colors = [
      '#f87171', // red-400
      '#fb923c', // orange-400
      '#fbbf24', // amber-400
      '#34d399', // emerald-400
      '#60a5fa', // blue-400
      '#a78bfa', // violet-400
      '#f472b6'  // pink-400
    ];
    
    const colorIndex = typeof folderId === 'number' 
      ? folderId % colors.length 
      : String(folderId).charCodeAt(0) % colors.length;
    
    return colors[colorIndex];
  }, []);
  
  // Prepare initial graph data from notes and folders
  const prepareGraphData = useCallback((notesData, foldersData, favoritesData) => {
    if (!notesData || notesData.length === 0) {
      setGraphData({ nodes: [], links: [] });
      return;
    }
    
    // Create set of favorite IDs for faster lookup
    const favoriteIds = new Set(favoritesData || []);
    
    // Create nodes for each note
    const nodes = notesData.map(note => {
      const folderObj = foldersData.find(f => f.id === note.folder_id);
      const tagCount = (note.tags?.length || 0) * 0.15;
      
      return {
        id: note.id,
        title: note.title,
        val: 1 + (note.content?.length ? Math.min(note.content.length / 1000, 2) : 0) * settings.nodeSize + tagCount,
        color: note.folder_id ? getFolderColor(note.folder_id) : COLORS.node.default,
        folderName: folderObj ? folderObj.name : 'Uncategorized',
        folderId: note.folder_id,
        isFavorite: favoriteIds.has(note.id),
        fontSize: 12 * settings.labelScale,
        content: note.content ? note.content.substring(0, 150) + '...' : '',
        tags: note.tags || [],
        highlighted: false,
        // Add initial random positions for animation
        x: Math.random() * 1000 - 500,
        y: Math.random() * 1000 - 500
      };
    });
    
    // Create links based on content references
    const links = extractLinks(notesData);
    
    // Process links to add proper colors based on type
    const processedLinks = links.map(link => ({
      ...link,
      color: getColorForLinkType(link.type, link.value)
    }));
    
    setGraphData({ nodes, links: processedLinks });
  }, [getFolderColor, settings.nodeSize, settings.labelScale]);
  
  // Get color for different link types
  const getColorForLinkType = (type, value) => {
    const linkType = LINK_TYPES.find(lt => lt.id === type);
    return linkType ? linkType.color : COLORS.link.folder;
  };
  
  // Initialize graph data and filters
  useEffect(() => {
    // Use sample data if needed or parse provided notes
    const notesToUse = (notes.length === 0 || isDemoMode) 
      ? require('./SampleData').generateSampleData().notes 
      : notes;
      
    const foldersToUse = (folders.length === 0 || isDemoMode) 
      ? require('./SampleData').generateSampleData().folders 
      : folders;
      
    const favoritesToUse = (favorites.length === 0 || isDemoMode) 
      ? require('./SampleData').generateSampleData().favorites 
      : favorites;
    
    // Prepare graph data
    prepareGraphData(notesToUse, foldersToUse, favoritesToUse);
    
    // Initialize folder filters
    const folderFilters = {};
    foldersToUse.forEach(folder => {
      folderFilters[folder.id] = true;
    });
    folderFilters['uncategorized'] = true;
    
    // Initialize tag filters
    const allTags = new Set();
    notesToUse.forEach(note => {
      if (note.tags && Array.isArray(note.tags)) {
        note.tags.forEach(tag => allTags.add(tag));
      }
    });
    
    const tagFilters = {};
    allTags.forEach(tag => {
      tagFilters[tag] = true;
    });
    
    // Set filters
    setFilters(prev => ({
      ...prev,
      selectedFolders: folderFilters,
      selectedTags: tagFilters
    }));
    
    setIsLoading(false);
    
    // Add timeout for node animation
    const timer = setTimeout(() => {
      setAnimateNodes(false);
    }, 2000);
    
    return () => clearTimeout(timer);
  }, [notes, folders, favorites, isDemoMode, prepareGraphData]);
  
  // Apply filters and search to graph data
  useEffect(() => {
    if (!graphData.nodes.length) {
      setFilteredGraphData(graphData);
      return;
    }
    
    // Filter nodes
    const filteredNodes = graphData.nodes.filter(node => {
      // Filter by search term
      if (searchTerm) {
        if (searchMode === 'title' && !node.title.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
        if (searchMode === 'content' && !node.content.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
        if (searchMode === 'tags' && !node.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))) {
          return false;
        }
      }
      
      // Filter by favorites
      if (node.isFavorite && !filters.showFavorites) {
        return false;
      }
      
      // Filter by non-favorites
      if (!node.isFavorite && !filters.showAllNotes) {
        return false;
      }
      
      // Filter by folder
      if (node.folderId && !filters.selectedFolders[node.folderId]) {
        return false;
      }
      
      // Handle uncategorized
      if (!node.folderId && !filters.selectedFolders['uncategorized']) {
        return false;
      }
      
      // Filter by tags
      if (node.tags && node.tags.length > 0) {
        // Check if any of the node's tags are selected in filters
        if (!node.tags.some(tag => filters.selectedTags[tag])) {
          return false;
        }
      }
      
      return true;
    });
    
    // Get filtered node IDs for link filtering
    const filteredNodeIds = new Set(filteredNodes.map(node => node.id));
    
    // Filter links to only include connections between visible nodes
    const filteredLinks = graphData.links.filter(link => {
      const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
      const targetId = typeof link.target === 'object' ? link.target.id : link.target;
      return filteredNodeIds.has(sourceId) && filteredNodeIds.has(targetId);
    });
    
    // Add connection count to nodes
    const nodeConnectionCounts = new Map();
    filteredLinks.forEach(link => {
      const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
      const targetId = typeof link.target === 'object' ? link.target.id : link.target;
      
      nodeConnectionCounts.set(sourceId, (nodeConnectionCounts.get(sourceId) || 0) + 1);
      nodeConnectionCounts.set(targetId, (nodeConnectionCounts.get(targetId) || 0) + 1);
    });
    
    // Filter by connection count
    const connectionFilteredNodes = filteredNodes.filter(node => {
      const connectionCount = nodeConnectionCounts.get(node.id) || 0;
      return connectionCount >= filters.minConnections && 
             connectionCount <= filters.maxConnections;
    });
    
    // Re-filter links based on connection-filtered nodes
    const connectionFilteredNodeIds = new Set(connectionFilteredNodes.map(node => node.id));
    const finalFilteredLinks = filteredLinks.filter(link => {
      const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
      const targetId = typeof link.target === 'object' ? link.target.id : link.target;
      return connectionFilteredNodeIds.has(sourceId) && connectionFilteredNodeIds.has(targetId);
    });
    
    setFilteredGraphData({ 
      nodes: connectionFilteredNodes, 
      links: finalFilteredLinks 
    });
  }, [graphData, searchTerm, searchMode, filters]);
  
  // Handle node click
  const handleNodeClick = useCallback(node => {
    if (isCreatingLink) {
      if (linkSourceNode) {
        // Create a link between source and target nodes
        if (linkSourceNode.id !== node.id) {
          const newLink = {
            source: linkSourceNode.id,
            target: node.id,
            type: linkType,
            value: LINK_TYPES.find(lt => lt.id === linkType)?.value || 2,
            color: getColorForLinkType(linkType),
            highlighted: true,
            id: `${linkSourceNode.id}-${node.id}-${linkType}-${Date.now()}` // Unique ID with timestamp
          };
          
          // Add to graph data
          setGraphData(prevData => ({
            nodes: prevData.nodes,
            links: [...prevData.links, newLink]
          }));
          
          // If onUpdateNote is provided, call it to update the source note content
          // to include a wiki link to the target note if linkType is wiki
          if (onUpdateNote && typeof onUpdateNote === 'function' && linkType === 'wiki') {
            const sourceNote = graphData.nodes.find(n => n.id === linkSourceNode.id);
            const targetNote = graphData.nodes.find(n => n.id === node.id);
            
            if (sourceNote && targetNote) {
              const updatedContent = sourceNote.content + `\n\nSee also: [[${targetNote.title}]]`;
              onUpdateNote(sourceNote.id, { content: updatedContent });
            }
          }
        }
        
        // Reset linking mode
        setIsCreatingLink(false);
        setLinkSourceNode(null);
      } else {
        // Set as source node
        setLinkSourceNode(node);
      }
    } else {
      // Highlight connections
      const highlightedNodeIds = new Set();
      const highlightedLinkIds = new Set();
      
      if (selectedNode && selectedNode.id === node.id) {
        // Deselect if already selected
        setSelectedNode(null);
        setHighlightedNodes(new Set());
        setHighlightedLinks(new Set());
      } else {
        // Select node and highlight connections
        setSelectedNode(node);
        highlightedNodeIds.add(node.id);
        
        // Find connected links and nodes
        filteredGraphData.links.forEach(link => {
          const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
          const targetId = typeof link.target === 'object' ? link.target.id : link.target;
          
          if (sourceId === node.id || targetId === node.id) {
            highlightedLinkIds.add(link.id || `${sourceId}-${targetId}-${link.type}`);
            
            if (sourceId === node.id) {
              highlightedNodeIds.add(targetId);
            } else {
              highlightedNodeIds.add(sourceId);
            }
          }
        });
        
        setHighlightedNodes(highlightedNodeIds);
        setHighlightedLinks(highlightedLinkIds);
      }
    }
  }, [filteredGraphData, selectedNode, isCreatingLink, linkSourceNode, onUpdateNote, graphData.nodes, linkType]);
  
  // Handle node double click to navigate to note
  const handleNodeDoubleClick = useCallback(node => {
    if (onNavigateToNote) {
      onNavigateToNote(node);
    } else {
      navigate(`/notes/${node.id}`);
    }
  }, [navigate, onNavigateToNote]);
  
  // Handle link click
  const handleLinkClick = useCallback((link) => {
    if (isEditMode) {
      setSelectedLink(link);
      
      // Highlight the link and its connected nodes
      const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
      const targetId = typeof link.target === 'object' ? link.target.id : link.target;
      
      const highlightedNodeIds = new Set([sourceId, targetId]);
      const highlightedLinkIds = new Set([link.id || `${sourceId}-${targetId}-${link.type}`]);
      
      setHighlightedNodes(highlightedNodeIds);
      setHighlightedLinks(highlightedLinkIds);
    }
  }, [isEditMode]);
  
  // Handle background click
  const handleBackgroundClick = useCallback(() => {
    setSelectedNode(null);
    setSelectedLink(null);
    setHighlightedNodes(new Set());
    setHighlightedLinks(new Set());
    
    if (isCreatingLink) {
      setIsCreatingLink(false);
      setLinkSourceNode(null);
    }
    
    if (isCreatingNode) {
      setIsCreatingNode(false);
      setNewNodeData({ title: '', content: '', folder_id: null, tags: [] });
    }
  }, [isCreatingLink, isCreatingNode]);
  
  // Handle creating a new node
  const handleCreateNode = useCallback(() => {
    if (!newNodeData.title.trim()) {
      alert('Node title is required');
      return;
    }
    
    const newNodeId = Date.now().toString();
    const newNode = {
      id: newNodeId,
      title: newNodeData.title,
      content: newNodeData.content || '',
      folder_id: newNodeData.folder_id,
      tags: newNodeData.tags || [],
      val: 1.5 * settings.nodeSize,
      color: newNodeData.folder_id ? getFolderColor(newNodeData.folder_id) : COLORS.node.default,
      folderName: folders.find(f => f.id === newNodeData.folder_id)?.name || 'Uncategorized',
      isFavorite: false,
      fontSize: 12 * settings.labelScale,
      x: Math.random() * 200 - 100 + (selectedNode ? selectedNode.x : 0),
      y: Math.random() * 200 - 100 + (selectedNode ? selectedNode.y : 0)
    };
    
    // Add to graph data
    setGraphData(prevData => ({
      nodes: [...prevData.nodes, newNode],
      links: prevData.links
    }));
    
    // If onCreateNote is provided, call it to create the actual note
    if (onCreateNote && typeof onCreateNote === 'function') {
      onCreateNote({
        id: newNodeId,
        title: newNodeData.title,
        content: newNodeData.content || '',
        folder_id: newNodeData.folder_id,
        tags: newNodeData.tags || []
      });
    }
    
    // If a node was selected when creating, automatically create a link to it
    if (selectedNode) {
      const newLink = {
        source: selectedNode.id,
        target: newNodeId,
        type: linkType,
        value: LINK_TYPES.find(lt => lt.id === linkType)?.value || 2,
        color: getColorForLinkType(linkType),
        highlighted: false,
        id: `${selectedNode.id}-${newNodeId}-${linkType}-${Date.now()}`
      };
      
      setGraphData(prevData => ({
        nodes: prevData.nodes,
        links: [...prevData.links, newLink]
      }));
    }
    
    // Reset state
    setIsCreatingNode(false);
    setNewNodeData({ title: '', content: '', folder_id: null, tags: [] });
  }, [newNodeData, settings.nodeSize, getFolderColor, folders, settings.labelScale, selectedNode, onCreateNote, linkType]);
  
  // Handle deleting a node
  const handleDeleteNode = useCallback((node) => {
    if (!node) return;
    
    // Remove the node and all its connections
    setGraphData(prevData => {
      const updatedLinks = prevData.links.filter(link => {
        const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
        const targetId = typeof link.target === 'object' ? link.target.id : link.target;
        return sourceId !== node.id && targetId !== node.id;
      });
      
      const updatedNodes = prevData.nodes.filter(n => n.id !== node.id);
      
      return { nodes: updatedNodes, links: updatedLinks };
    });
    
    // If onDeleteNote is provided, call it to delete the actual note
    if (onDeleteNote && typeof onDeleteNote === 'function') {
      onDeleteNote(node.id);
    }
    
    // Reset state
    setSelectedNode(null);
    setHighlightedNodes(new Set());
    setHighlightedLinks(new Set());
    setConfirmDeleteNode(null);
  }, [onDeleteNote]);
  
  // Handle deleting a link
  const handleDeleteLink = useCallback((link) => {
    if (!link) return;
    
    const linkId = link.id || 
      `${typeof link.source === 'object' ? link.source.id : link.source}-${typeof link.target === 'object' ? link.target.id : link.target}-${link.type}`;
    
    // Remove the link
    setGraphData(prevData => {
      const updatedLinks = prevData.links.filter(l => {
        const currentLinkId = l.id || 
          `${typeof l.source === 'object' ? l.source.id : l.source}-${typeof l.target === 'object' ? l.target.id : l.target}-${l.type}`;
        
        return currentLinkId !== linkId;
      });
      
      return { nodes: prevData.nodes, links: updatedLinks };
    });
    
    // If the link type is wiki and onUpdateNote is provided, we could potentially
    // update the source note's content to remove the wiki link, but this would require
    // more sophisticated content parsing
    
    // Reset state
    setSelectedLink(null);
    setHighlightedLinks(new Set());
    setConfirmDeleteLink(null);
  }, []);
  
  // Handle changing link type
  const handleChangeLinkType = useCallback((link, newType) => {
    if (!link) return;
    
    const linkId = link.id || 
      `${typeof link.source === 'object' ? link.source.id : link.source}-${typeof link.target === 'object' ? link.target.id : link.target}-${link.type}`;
    
    // Update the link type
    setGraphData(prevData => {
      const updatedLinks = prevData.links.map(l => {
        const currentLinkId = l.id || 
          `${typeof l.source === 'object' ? l.source.id : l.source}-${typeof l.target === 'object' ? l.target.id : l.target}-${l.type}`;
        
        if (currentLinkId === linkId) {
          return {
            ...l,
            type: newType,
            value: LINK_TYPES.find(lt => lt.id === newType)?.value || l.value,
            color: getColorForLinkType(newType)
          };
        }
        
        return l;
      });
      
      return { nodes: prevData.nodes, links: updatedLinks };
    });
  }, []);
  
  // Toggle favorite status for a node
  const toggleFavorite = useCallback((node) => {
    if (!node) return;
    
    // Update the node's favorite status
    setGraphData(prevData => {
      const updatedNodes = prevData.nodes.map(n => {
        if (n.id === node.id) {
          return { ...n, isFavorite: !n.isFavorite };
        }
        return n;
      });
      
      return { nodes: updatedNodes, links: prevData.links };
    });
    
    // If a node is selected, update the selected node reference
    if (selectedNode && selectedNode.id === node.id) {
      setSelectedNode(prev => ({ ...prev, isFavorite: !prev.isFavorite }));
    }
  }, [selectedNode]);
  
  // Zoom controls
  const zoomIn = useCallback(() => {
    const graphRef = is3D ? graph3DRef : graph2DRef;
    if (graphRef.current) {
      const currentZoom = graphRef.current.zoom();
      graphRef.current.zoom(currentZoom * 1.2, 400);
    }
  }, [is3D]);
  
  const zoomOut = useCallback(() => {
    const graphRef = is3D ? graph3DRef : graph2DRef;
    if (graphRef.current) {
      const currentZoom = graphRef.current.zoom();
      graphRef.current.zoom(currentZoom / 1.2, 400);
    }
  }, [is3D]);
  
  const resetZoom = useCallback(() => {
    const graphRef = is3D ? graph3DRef : graph2DRef;
    if (graphRef.current) {
      graphRef.current.zoomToFit(600, 40);
    }
  }, [is3D]);
  
  // Update settings
  const updateSetting = useCallback((key, value) => {
    setSettings(prev => ({
      ...prev,
      [key]: value
    }));
    
    // Apply settings to graph immediately for some properties
    const graphRef = is3D ? graph3DRef : graph2DRef;
    if (graphRef.current) {
      switch (key) {
        case 'linkDistance':
          graphRef.current.d3Force('link').distance(value);
          graphRef.current.d3ReheatSimulation();
          break;
        case 'chargeStrength':
          graphRef.current.d3Force('charge').strength(value);
          graphRef.current.d3ReheatSimulation();
          break;
        case 'nodeSize':
          graphRef.current.nodeRelSize(6 * value);
          break;
        case 'dagMode':
          graphRef.current.dagMode(value);
          graphRef.current.d3ReheatSimulation();
          break;
        default:
          break;
      }
    }
  }, [is3D]);
  
  // Toggle layout
  const toggleLayout = useCallback((newLayout) => {
    setLayout(newLayout);
    
    const graphRef = is3D ? graph3DRef : graph2DRef;
    if (!graphRef.current) return;
    
    // Reset existing forces
    graphRef.current.dagMode(null);
    
    switch (newLayout) {
      case 'force':
        // Default force-directed layout
        graphRef.current.d3Force('charge').strength(settings.chargeStrength);
        graphRef.current.d3Force('link').distance(settings.linkDistance);
        break;
        
      case 'radial':
        // Create a radial layout
        const nodes = filteredGraphData.nodes;
        const center = {x: 0, y: 0};
        
        // Find the most connected node
        const connectionCounts = new Map();
        filteredGraphData.links.forEach(link => {
          const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
          const targetId = typeof link.target === 'object' ? link.target.id : link.target;
          
          connectionCounts.set(sourceId, (connectionCounts.get(sourceId) || 0) + 1);
          connectionCounts.set(targetId, (connectionCounts.get(targetId) || 0) + 1);
        });
        
        let centralNodeId = null;
        let maxConnections = -1;
        
        connectionCounts.forEach((count, nodeId) => {
          if (count > maxConnections) {
            maxConnections = count;
            centralNodeId = nodeId;
          }
        });
        
        // Apply radial force
        graphRef.current.d3Force('radial', d3.forceRadial()
          .radius(node => {
            // Central node at center
            if (node.id === centralNodeId) return 0;
            
            // Favorites in inner circle
            if (node.isFavorite) return 100;
            
            // Others based on connection count
            const connections = connectionCounts.get(node.id) || 0;
            return 150 + (10 * (10 - Math.min(connections, 10)));
          })
          .strength(0.8)
          .x(center.x)
          .y(center.y)
        );
        break;
        
      case 'circle':
        // Simple circle layout
        graphRef.current.d3Force('radial', d3.forceRadial()
          .radius(200)
          .strength(1)
        );
        break;
        
      case 'hierarchy':
        // Hierarchical layout
        graphRef.current.dagMode('td');
        graphRef.current.dagLevelDistance(80);
        break;
        
      default:
        break;
    }
    
    // Reheat the simulation
    graphRef.current.d3ReheatSimulation();
  }, [filteredGraphData, settings.chargeStrength, settings.linkDistance, is3D]);
  
  // Graph node and link rendering functions
  const getNodeColor = useCallback((node) => {
    if (selectedNode && selectedNode.id === node.id) {
      return COLORS.node.selected;
    }
    if (highlightedNodes.has(node.id)) {
      return COLORS.node.highlighted;
    }
    if (isCreatingLink && linkSourceNode && linkSourceNode.id === node.id) {
      return COLORS.node.selected;
    }
    return node.color;
  }, [selectedNode, highlightedNodes, isCreatingLink, linkSourceNode]);
  
  const getLinkColor = useCallback((link) => {
    const linkId = link.id || 
      `${typeof link.source === 'object' ? link.source.id : link.source}-${typeof link.target === 'object' ? link.target.id : link.target}-${link.type}`;
      
    if (highlightedLinks.has(linkId)) {
      return COLORS.link.highlighted;
    }
    if (selectedLink && selectedLink.id === link.id) {
      return COLORS.link.highlighted;
    }
    return link.color;
  }, [highlightedLinks, selectedLink]);
  
  const getLinkWidth = useCallback((link) => {
    const linkId = link.id || 
      `${typeof link.source === 'object' ? link.source.id : link.source}-${typeof link.target === 'object' ? link.target.id : link.target}-${link.type}`;
      
    if (highlightedLinks.has(linkId) || (selectedLink && selectedLink.id === link.id)) {
      return settings.linkWidth * 2;
    }
    return settings.linkWidth * (link.value || 1);
  }, [highlightedLinks, settings.linkWidth, selectedLink]);
  
  // Enhanced node rendering with glow effects
  const getNodeCanvasObject = useCallback((node, ctx, globalScale) => {
    const size = node.val ? node.val * 4 : 4;
    const fontSize = node.fontSize ? node.fontSize : 12;
    const isHighlighted = highlightedNodes.has(node.id) || (selectedNode && selectedNode.id === node.id);
    const color = getNodeColor(node);
    
    // Draw glow effect for all nodes
    ctx.shadowColor = color;
    ctx.shadowBlur = isHighlighted ? 20 : 8;
    
    // Draw node
    ctx.beginPath();
    ctx.arc(node.x, node.y, size, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.fill();
    
    // Reset shadow for performance
    ctx.shadowBlur = 0;
    
    // Add border for selected/highlighted node
    if (isHighlighted) {
      ctx.strokeStyle = COLORS.node.highlighted;
      ctx.lineWidth = 2;
      ctx.stroke();
      
      // Add pulsing effect
      const pulseSize = size + 5 + Math.sin(Date.now() / 200) * 3;
      ctx.beginPath();
      ctx.arc(node.x, node.y, pulseSize, 0, 2 * Math.PI);
      ctx.strokeStyle = 'rgba(244, 63, 94, 0.3)';
      ctx.lineWidth = 2;
      ctx.stroke();
    }
    
    // Add favorite star if applicable
    if (node.isFavorite) {
      const starSize = size * 0.7;
      
      // Draw star
      ctx.fillStyle = COLORS.node.favorite;
      ctx.beginPath();
      
      // Draw a star shape
      const spikes = 5;
      const outerRadius = starSize;
      const innerRadius = starSize * 0.4;
      
      for (let i = 0; i < spikes * 2; i++) {
        const radius = i % 2 === 0 ? outerRadius : innerRadius;
        const angle = (Math.PI / spikes) * i;
        const x = node.x + Math.cos(angle) * radius;
        const y = node.y + Math.sin(angle) * radius;
        
        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      }
      
      ctx.closePath();
      ctx.fill();
    }
    
    // Draw label if enabled and zoomed in enough
    const isLabelVisible = settings.showLabels && 
      (globalScale > 1.2 || isHighlighted || node.val > 1.5);
    
    if (isLabelVisible) {
      // Background for label
      const label = node.title;
      ctx.font = `${isHighlighted ? 'bold ' : ''}${fontSize / globalScale}px Inter, Arial, sans-serif`;
      const textWidth = ctx.measureText(label).width;
      const backgroundHeight = fontSize / globalScale + 6;
      const backgroundY = node.y + size + 10;
      
      // Create slightly curved background with rounded corners
      const cornerRadius = 4 / globalScale;
      const bgX = node.x - textWidth / 2 - 6;
      const bgY = backgroundY - backgroundHeight / 2;
      const bgWidth = textWidth + 12;
      const bgHeight = backgroundHeight;
      
      // Draw glowing background for labels
      ctx.fillStyle = isHighlighted ? 'rgba(244, 63, 94, 0.2)' : 'rgba(0, 0, 0, 0.7)';
      ctx.beginPath();
      ctx.moveTo(bgX + cornerRadius, bgY);
      ctx.lineTo(bgX + bgWidth - cornerRadius, bgY);
      ctx.quadraticCurveTo(bgX + bgWidth, bgY, bgX + bgWidth, bgY + cornerRadius);
      ctx.lineTo(bgX + bgWidth, bgY + bgHeight - cornerRadius);
      ctx.quadraticCurveTo(bgX + bgWidth, bgY + bgHeight, bgX + bgWidth - cornerRadius, bgY + bgHeight);
      ctx.lineTo(bgX + cornerRadius, bgY + bgHeight);
      ctx.quadraticCurveTo(bgX, bgY + bgHeight, bgX, bgY + bgHeight - cornerRadius);
      ctx.lineTo(bgX, bgY + cornerRadius);
      ctx.quadraticCurveTo(bgX, bgY, bgX + cornerRadius, bgY);
      ctx.closePath();
      ctx.fill();
      
      if (isHighlighted) {
        ctx.strokeStyle = 'rgba(244, 63, 94, 0.5)';
        ctx.lineWidth = 1 / globalScale;
        ctx.stroke();
      }
      
      // Draw text
      ctx.fillStyle = node.isFavorite ? COLORS.node.favorite : COLORS.node.text;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(label, node.x, backgroundY);
      
      // Draw tag indicators if node has tags and setting is enabled
      if (settings.showTagsOnNodes && node.tags && node.tags.length > 0 && (isHighlighted || globalScale > 1.8)) {
        const tagY = backgroundY + backgroundHeight + 4;
        const tagSize = 8 / globalScale;
        const tagSpacing = 4 / globalScale;
        const totalWidth = node.tags.length * tagSize + (node.tags.length - 1) * tagSpacing;
        let tagX = node.x - totalWidth / 2;
        
        node.tags.slice(0, 5).forEach((_, index) => {
          ctx.beginPath();
          ctx.arc(tagX + tagSize / 2, tagY, tagSize / 2, 0, 2 * Math.PI);
          ctx.fillStyle = getTagColor(index);
          ctx.fill();
          
          tagX += tagSize + tagSpacing;
        });
        
        // If there are more tags than shown
        if (node.tags.length > 5) {
          ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
          ctx.textAlign = 'left';
          ctx.font = `${8 / globalScale}px Inter, Arial, sans-serif`;
          ctx.fillText(`+${node.tags.length - 5}`, tagX, tagY);
        }
      }
    }
  }, [highlightedNodes, selectedNode, getNodeColor, settings]);
  
  // Color generator for tags
  const getTagColor = (index) => {
    const tagColors = [
      '#f43f5e', // rose-500
      '#3b82f6', // blue-500
      '#10b981', // emerald-500
      '#eab308', // yellow-500
      '#8b5cf6', // violet-500
      '#ec4899', // pink-500
      '#f97316', // orange-500
      '#14b8a6', // teal-500
    ];
    
    return tagColors[index % tagColors.length];
  };
  
  // Node label for 3D mode
  const getNodeThreeObject = useCallback((node) => {
    const isHighlighted = highlightedNodes.has(node.id) || (selectedNode && selectedNode.id === node.id);
    
    if (settings.showLabels && (isHighlighted || node.val > 1.5)) {
      const sprite = new SpriteText(node.title);
      sprite.color = COLORS.node.text;
      sprite.textHeight = node.val * 1.5;
      sprite.backgroundColor = 'rgba(0, 0, 0, 0.7)';
      sprite.padding = 2;
      sprite.borderRadius = 3;
      sprite.position.y = node.val * 6;
      return sprite;
    }
    
    return null;
  }, [highlightedNodes, selectedNode, settings.showLabels]);
  
  // Right panel content
  const renderRightPanel = () => {
    switch (visibleRightPanel) {
      case 'info':
        return renderNodeInfoPanel();
      case 'settings':
        return renderSettingsPanel();
      case 'filter':
        return renderFilterPanel();
      default:
        return null;
    }
  };
  
  // Node info panel
  const renderNodeInfoPanel = () => {
    if (!selectedNode) return null;
    
    const connectionCount = filteredGraphData.links.filter(link => {
      const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
      const targetId = typeof link.target === 'object' ? link.target.id : link.target;
      return sourceId === selectedNode.id || targetId === selectedNode.id;
    }).length;
    
    return (
      <div className="h-full overflow-auto">
        <div className="p-4">
          <div className="flex justify-between items-start">
            <div className="flex items-center">
              {selectedNode.isFavorite && (
                <Heart size={16} className="text-yellow-400 mr-2" fill="currentColor" />
              )}
              <h3 className="text-base font-medium text-white truncate max-w-[200px]">
                {selectedNode.title}
              </h3>
            </div>
            
            {isEditMode && (
              <div className="flex space-x-1">
                <button
                  onClick={() => toggleFavorite(selectedNode)}
                  className="p-1 rounded hover:bg-gray-800 text-gray-400 hover:text-yellow-400"
                  title={selectedNode.isFavorite ? "Remove from favorites" : "Add to favorites"}
                >
                  <Heart size={16} fill={selectedNode.isFavorite ? "currentColor" : "none"} />
                </button>
                <button
                  onClick={() => setConfirmDeleteNode(selectedNode)}
                  className="p-1 rounded hover:bg-gray-800 text-gray-400 hover:text-red-400"
                  title="Delete node"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            )}
          </div>
          
          <div className="flex items-center mt-2 text-sm text-gray-400">
            <Folder size={14} className="mr-2" />
            <span 
              className="w-3 h-3 rounded-full mr-2"
              style={{ backgroundColor: selectedNode.color }}
            ></span>
            {selectedNode.folderName}
          </div>
          
          {selectedNode.tags && selectedNode.tags.length > 0 && (
            <div className="mt-3">
              <div className="flex flex-wrap gap-1">
                {selectedNode.tags.map((tag, index) => (
                  <span 
                    key={index}
                    className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-slate-800 text-blue-400"
                  >
                    <Hash size={10} className="mr-1" />
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          )}
          
          {selectedNode.content && (
            <div className="mt-4 text-sm text-gray-300 border-t border-gray-800 pt-3">
              <p className="line-clamp-6">{selectedNode.content}</p>
            </div>
          )}
          
          <div className="mt-4 flex flex-col">
            <div className="text-sm text-gray-400 mb-2">
              <span className="font-medium">Connections:</span>
              <span className="ml-1 text-rose-400 font-bold">{connectionCount}</span>
            </div>
            
            {/* Connected notes list */}
            {connectionCount > 0 && (
              <div className="mt-2 border-t border-gray-800 pt-3">
                <h4 className="text-xs uppercase font-semibold text-gray-500 mb-2">Connected Notes</h4>
                <div className="space-y-2 max-h-60 overflow-y-auto pr-1">
                  {filteredGraphData.links
                    .filter(link => {
                      const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
                      const targetId = typeof link.target === 'object' ? link.target.id : link.target;
                      return sourceId === selectedNode.id || targetId === selectedNode.id;
                    })
                    .map(link => {
                      const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
                      const targetId = typeof link.target === 'object' ? link.target.id : link.target;
                      const connectedNodeId = sourceId === selectedNode.id ? targetId : sourceId;
                      const connectedNode = filteredGraphData.nodes.find(n => n.id === connectedNodeId);
                      const linkTypeInfo = LINK_TYPES.find(lt => lt.id === link.type) || { name: 'Connection', color: COLORS.link.folder };
                      
                      if (!connectedNode) return null;
                      
                      return (
                        <div 
                          key={link.id || `${sourceId}-${targetId}-${link.type}`}
                          className="flex items-center p-2 rounded hover:bg-slate-800 cursor-pointer group"
                          onClick={() => handleNodeClick(connectedNode)}
                        >
                          <div 
                            className="w-2 h-2 rounded-full mr-2" 
                            style={{ backgroundColor: link.color }}
                          ></div>
                          <span className="text-sm text-gray-300 group-hover:text-white truncate">
                            {connectedNode.title}
                          </span>
                          {connectedNode.isFavorite && (
                            <Heart size={10} className="ml-1 text-yellow-400" fill="currentColor" />
                          )}
                          <span className="ml-auto text-xs text-gray-500 group-hover:text-gray-400">
                            {linkTypeInfo.name}
                          </span>
                          
                          {isEditMode && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedLink(link);
                                setConfirmDeleteLink(link);
                              }}
                              className="ml-2 p-1 opacity-0 group-hover:opacity-100 text-gray-500 hover:text-red-400"
                              title="Remove connection"
                            >
                              <Scissors size={12} />
                            </button>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
          
          <div className="mt-4 flex justify-end space-x-2">
            {isEditMode && (
              <button
                onClick={() => {
                  setIsCreatingLink(true);
                  setLinkSourceNode(selectedNode);
                }}
                className="px-3 py-1.5 bg-slate-700 hover:bg-slate-600 text-white text-xs rounded-md transition-colors flex items-center"
              >
                <Link size={12} className="mr-1.5" />
                Connect
              </button>
            )}
            
            <button
              onClick={() => handleNodeDoubleClick(selectedNode)}
              className="px-3 py-1.5 bg-rose-600 hover:bg-rose-500 text-white text-xs rounded-md transition-colors flex items-center"
            >
              <Book size={12} className="mr-1.5" />
              Open Note
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  // Settings panel
  const renderSettingsPanel = () => {
    return (
      <div className="h-full overflow-auto">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-sm font-medium text-white">Graph Settings</h3>
            <button 
              onClick={() => setSettings({
                nodeSize: 1.5,
                linkDistance: 140,
                chargeStrength: -200,
                showLabels: true,
                showTagsOnNodes: true,
                labelScale: 1.2,
                particlesPerLink: 3,
                particleSpeed: 0.006,
                centerForce: 0.3,
                linkWidth: 1.8,
                dagMode: null,
                showArrows: true
              })}
              className="text-xs text-gray-400 hover:text-white px-2 py-1 rounded hover:bg-gray-800"
            >
              Reset defaults
            </button>
          </div>
          
          <div className="space-y-5">
            {/* Visualization section */}
            <div>
              <h4 className="text-xs uppercase font-semibold text-gray-500 mb-3">Visualization</h4>
              
              <div className="grid grid-cols-1 gap-4">
                {/* Theme selector */}
                <div>
                  <label className="text-xs text-gray-400 mb-1 block">Theme</label>
                  <div className="flex space-x-2">
                    {Object.keys(THEMES).map(themeName => (
                      <button
                        key={themeName}
                        onClick={() => setTheme(themeName)}
                        className={`w-6 h-6 rounded-full flex items-center justify-center transition-all ${
                          theme === themeName ? 'ring-2 ring-rose-500 ring-offset-1 ring-offset-gray-900' : ''
                        }`}
                        style={{ backgroundColor: THEMES[themeName].background }}
                        title={themeName.charAt(0).toUpperCase() + themeName.slice(1)}
                      >
                        {theme === themeName && (
                          <div className="w-2 h-2 rounded-full bg-rose-500"></div>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
                
                {/* Dimension toggle */}
                <div>
                  <label className="text-xs text-gray-400 mb-1 block">Dimension</label>
                  <div className="flex rounded-md overflow-hidden w-full bg-gray-800">
                    <button 
                      onClick={() => setIs3D(false)}
                      className={`flex-1 py-1.5 text-xs font-medium transition-colors ${
                        !is3D ? 'bg-rose-600 text-white' : 'text-gray-400 hover:text-white'
                      }`}
                    >
                      2D
                    </button>
                    <button 
                      onClick={() => setIs3D(true)}
                      className={`flex-1 py-1.5 text-xs font-medium transition-colors ${
                        is3D ? 'bg-rose-600 text-white' : 'text-gray-400 hover:text-white'
                      }`}
                    >
                      3D
                    </button>
                  </div>
                </div>
                
                {/* Layout selector */}
                <div>
                  <label className="text-xs text-gray-400 mb-1 block">Layout</label>
                  <div className="flex flex-wrap gap-2">
                    <button 
                      onClick={() => toggleLayout('force')}
                      className={`px-2 py-1 text-xs rounded-md transition-colors ${
                        layout === 'force' ? 'bg-rose-600 text-white' : 'bg-gray-800 text-gray-400 hover:text-white'
                      }`}
                    >
                      <Zap size={10} className="inline mr-1" />
                      Force
                    </button>
                    <button 
                      onClick={() => toggleLayout('radial')}
                      className={`px-2 py-1 text-xs rounded-md transition-colors ${
                        layout === 'radial' ? 'bg-rose-600 text-white' : 'bg-gray-800 text-gray-400 hover:text-white'
                      }`}
                    >
                      <Target size={10} className="inline mr-1" />
                      Radial
                    </button>
                    <button 
                      onClick={() => toggleLayout('circle')}
                      className={`px-2 py-1 text-xs rounded-md transition-colors ${
                        layout === 'circle' ? 'bg-rose-600 text-white' : 'bg-gray-800 text-gray-400 hover:text-white'
                      }`}
                    >
                      <Hexagon size={10} className="inline mr-1" />
                      Circle
                    </button>
                    <button 
                      onClick={() => toggleLayout('hierarchy')}
                      className={`px-2 py-1 text-xs rounded-md transition-colors ${
                        layout === 'hierarchy' ? 'bg-rose-600 text-white' : 'bg-gray-800 text-gray-400 hover:text-white'
                      }`}
                    >
                      <Layers size={10} className="inline mr-1" />
                      Hierarchy
                    </button>
                  </div>
                </div>
                
                {/* Toggle options */}
                <div className="space-y-2">
                  <div className="flex items-center">
                    <button 
                      onClick={() => updateSetting('showLabels', !settings.showLabels)}
                      className="flex items-center text-sm text-gray-300 hover:text-white"
                    >
                      {settings.showLabels ? 
                        <CheckSquare size={16} className="mr-2 text-rose-500" /> : 
                        <Square size={16} className="mr-2 text-gray-500" />
                      }
                      Show labels
                    </button>
                  </div>
                  
                  <div className="flex items-center">
                    <button 
                      onClick={() => updateSetting('showTagsOnNodes', !settings.showTagsOnNodes)}
                      className="flex items-center text-sm text-gray-300 hover:text-white"
                    >
                      {settings.showTagsOnNodes ? 
                        <CheckSquare size={16} className="mr-2 text-rose-500" /> : 
                        <Square size={16} className="mr-2 text-gray-500" />
                      }
                      Show tag indicators
                    </button>
                  </div>
                  
                  <div className="flex items-center">
                    <button 
                      onClick={() => updateSetting('showArrows', !settings.showArrows)}
                      className="flex items-center text-sm text-gray-300 hover:text-white"
                    >
                      {settings.showArrows ? 
                        <CheckSquare size={16} className="mr-2 text-rose-500" /> : 
                        <Square size={16} className="mr-2 text-gray-500" />
                      }
                      Show arrows
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Node settings */}
            <div>
              <h4 className="text-xs uppercase font-semibold text-gray-500 mb-3">Nodes</h4>
              
              <div className="space-y-4">
                {/* Node size */}
                <div>
                  <label className="flex justify-between text-xs text-gray-400 mb-1">
                    Node Size
                    <span>{settings.nodeSize.toFixed(1)}x</span>
                  </label>
                  <div className="flex items-center">
                    <button 
                      onClick={() => updateSetting('nodeSize', Math.max(0.5, settings.nodeSize - 0.1))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-l"
                      disabled={settings.nodeSize <= 0.5}
                    >
                      <Minus size={14} />
                    </button>
                    <input
                      type="range"
                      min="0.5"
                      max="2"
                      step="0.1"
                      value={settings.nodeSize}
                      onChange={(e) => updateSetting('nodeSize', parseFloat(e.target.value))}
                      className="flex-grow h-1 mx-2 bg-gray-800 rounded-lg appearance-none cursor-pointer"
                    />
                    <button 
                      onClick={() => updateSetting('nodeSize', Math.min(2, settings.nodeSize + 0.1))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-r"
                      disabled={settings.nodeSize >= 2}
                    >
                      <Plus size={14} />
                    </button>
                  </div>
                </div>
                
                {/* Label scale */}
                <div>
                  <label className="flex justify-between text-xs text-gray-400 mb-1">
                    Label Size
                    <span>{settings.labelScale.toFixed(1)}x</span>
                  </label>
                  <div className="flex items-center">
                    <button 
                      onClick={() => updateSetting('labelScale', Math.max(0.5, settings.labelScale - 0.1))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-l"
                      disabled={settings.labelScale <= 0.5}
                    >
                      <Minus size={14} />
                    </button>
                    <input
                      type="range"
                      min="0.5"
                      max="2"
                      step="0.1"
                      value={settings.labelScale}
                      onChange={(e) => updateSetting('labelScale', parseFloat(e.target.value))}
                      className="flex-grow h-1 mx-2 bg-gray-800 rounded-lg appearance-none cursor-pointer"
                    />
                    <button 
                      onClick={() => updateSetting('labelScale', Math.min(2, settings.labelScale + 0.1))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-r"
                      disabled={settings.labelScale >= 2}
                    >
                      <Plus size={14} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Link settings */}
            <div>
              <h4 className="text-xs uppercase font-semibold text-gray-500 mb-3">Connections</h4>
              
              <div className="space-y-4">
                {/* Link distance */}
                <div>
                  <label className="flex justify-between text-xs text-gray-400 mb-1">
                    Link Distance
                    <span>{settings.linkDistance}px</span>
                  </label>
                  <div className="flex items-center">
                    <button 
                      onClick={() => updateSetting('linkDistance', Math.max(60, settings.linkDistance - 10))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-l"
                      disabled={settings.linkDistance <= 60}
                    >
                      <Minus size={14} />
                    </button>
                    <input
                      type="range"
                      min="60"
                      max="200"
                      step="10"
                      value={settings.linkDistance}
                      onChange={(e) => updateSetting('linkDistance', parseInt(e.target.value))}
                      className="flex-grow h-1 mx-2 bg-gray-800 rounded-lg appearance-none cursor-pointer"
                    />
                    <button 
                      onClick={() => updateSetting('linkDistance', Math.min(200, settings.linkDistance + 10))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-r"
                      disabled={settings.linkDistance >= 200}
                    >
                      <Plus size={14} />
                    </button>
                  </div>
                </div>
                
                {/* Charge strength */}
                <div>
                  <label className="flex justify-between text-xs text-gray-400 mb-1">
                    Force Strength
                    <span>{Math.abs(settings.chargeStrength)}</span>
                  </label>
                  <div className="flex items-center">
                    <button 
                      onClick={() => updateSetting('chargeStrength', Math.min(-60, settings.chargeStrength + 20))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-l"
                      disabled={settings.chargeStrength >= -60}
                    >
                      <Minus size={14} />
                    </button>
                    <input
                      type="range"
                      min="60"
                      max="300"
                      step="20"
                      value={Math.abs(settings.chargeStrength)}
                      onChange={(e) => updateSetting('chargeStrength', -parseInt(e.target.value))}
                      className="flex-grow h-1 mx-2 bg-gray-800 rounded-lg appearance-none cursor-pointer"
                    />
                    <button 
                      onClick={() => updateSetting('chargeStrength', Math.max(-300, settings.chargeStrength - 20))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-r"
                      disabled={settings.chargeStrength <= -300}
                    >
                      <Plus size={14} />
                    </button>
                  </div>
                </div>
                
                {/* Link width */}
                <div>
                  <label className="flex justify-between text-xs text-gray-400 mb-1">
                    Link Width
                    <span>{settings.linkWidth.toFixed(1)}</span>
                  </label>
                  <div className="flex items-center">
                    <button 
                      onClick={() => updateSetting('linkWidth', Math.max(0.5, settings.linkWidth - 0.2))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-l"
                      disabled={settings.linkWidth <= 0.5}
                    >
                      <Minus size={14} />
                    </button>
                    <input
                      type="range"
                      min="0.5"
                      max="3"
                      step="0.2"
                      value={settings.linkWidth}
                      onChange={(e) => updateSetting('linkWidth', parseFloat(e.target.value))}
                      className="flex-grow h-1 mx-2 bg-gray-800 rounded-lg appearance-none cursor-pointer"
                    />
                    <button 
                      onClick={() => updateSetting('linkWidth', Math.min(3, settings.linkWidth + 0.2))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-r"
                      disabled={settings.linkWidth >= 3}
                    >
                      <Plus size={14} />
                    </button>
                  </div>
                </div>
                
                {/* Particles per link */}
                <div>
                  <label className="flex justify-between text-xs text-gray-400 mb-1">
                    Particles Per Link
                    <span>{settings.particlesPerLink}</span>
                  </label>
                  <div className="flex items-center">
                    <button 
                      onClick={() => updateSetting('particlesPerLink', Math.max(0, settings.particlesPerLink - 1))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-l"
                      disabled={settings.particlesPerLink <= 0}
                    >
                      <Minus size={14} />
                    </button>
                    <input
                      type="range"
                      min="0"
                      max="5"
                      step="1"
                      value={settings.particlesPerLink}
                      onChange={(e) => updateSetting('particlesPerLink', parseInt(e.target.value))}
                      className="flex-grow h-1 mx-2 bg-gray-800 rounded-lg appearance-none cursor-pointer"
                    />
                    <button 
                      onClick={() => updateSetting('particlesPerLink', Math.min(5, settings.particlesPerLink + 1))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-r"
                      disabled={settings.particlesPerLink >= 5}
                    >
                      <Plus size={14} />
                    </button>
                  </div>
                </div>
                
                {/* Particle speed */}
                <div>
                  <label className="flex justify-between text-xs text-gray-400 mb-1">
                    Particle Speed
                    <span>{settings.particleSpeed.toFixed(3)}</span>
                  </label>
                  <div className="flex items-center">
                    <button 
                      onClick={() => updateSetting('particleSpeed', Math.max(0.001, settings.particleSpeed - 0.001))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-l"
                      disabled={settings.particleSpeed <= 0.001}
                    >
                      <Minus size={14} />
                    </button>
                    <input
                      type="range"
                      min="0.001"
                      max="0.01"
                      step="0.001"
                      value={settings.particleSpeed}
                      onChange={(e) => updateSetting('particleSpeed', parseFloat(e.target.value))}
                      className="flex-grow h-1 mx-2 bg-gray-800 rounded-lg appearance-none cursor-pointer"
                    />
                    <button 
                      onClick={() => updateSetting('particleSpeed', Math.min(0.01, settings.particleSpeed + 0.001))}
                      className="p-1 bg-gray-800 text-gray-400 rounded-r"
                      disabled={settings.particleSpeed >= 0.01}
                    >
                      <Plus size={14} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Export options */}
            <div>
              <h4 className="text-xs uppercase font-semibold text-gray-500 mb-3">Export</h4>
              
              <button 
                onClick={() => {
                  const graphRef = is3D ? graph3DRef : graph2DRef;
                  if (graphRef.current) {
                    const canvas = graphRef.current.renderer().domElement;
                    const dataURL = canvas.toDataURL('image/png');
                    
                    // Create download link
                    const link = document.createElement('a');
                    link.download = 'knowledge-graph.png';
                    link.href = dataURL;
                    link.click();
                  }
                }}
                className="px-3 py-1.5 bg-slate-700 hover:bg-slate-600 text-white text-xs rounded-md transition-colors flex items-center"
              >
                <Download size={12} className="mr-1.5" />
                Export as PNG
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  // Filter panel
  const renderFilterPanel = () => {
    // Get all available tags
    const allTags = new Set();
    graphData.nodes.forEach(node => {
      if (node.tags && Array.isArray(node.tags)) {
        node.tags.forEach(tag => allTags.add(tag));
      }
    });
    
    const tagsList = Array.from(allTags).sort();
    
    return (
      <div className="h-full overflow-auto">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-sm font-medium text-white">Filters</h3>
            <button 
              onClick={() => {
                // Reset all filters
                const folderFilters = {};
                folders.forEach(folder => {
                  folderFilters[folder.id] = true;
                });
                folderFilters['uncategorized'] = true;
                
                const tagFilters = {};
                tagsList.forEach(tag => {
                  tagFilters[tag] = true;
                });
                
                setFilters({
                  showFavorites: true,
                  showAllNotes: true,
                  selectedFolders: folderFilters,
                  selectedTags: tagFilters,
                  minConnections: 0,
                  maxConnections: 100
                });
                
                setSearchTerm('');
              }}
              className="text-xs text-gray-400 hover:text-white px-2 py-1 rounded hover:bg-gray-800"
            >
              Reset all
            </button>
          </div>
          
          <div className="space-y-5">
            {/* Note types */}
            <div>
              <h4 className="text-xs uppercase font-semibold text-gray-500 mb-3">Note Types</h4>
              
              <div className="space-y-2">
                <button 
                  onClick={() => setFilters({...filters, showFavorites: !filters.showFavorites})}
                  className="flex items-center text-sm text-gray-300 hover:text-white w-full px-2 py-1.5 rounded hover:bg-gray-800"
                >
                  {filters.showFavorites ? 
                    <CheckSquare size={16} className="mr-2 text-rose-500" /> : 
                    <Square size={16} className="mr-2 text-gray-500" />
                  }
                  <Heart size={14} className="mr-1.5 text-yellow-400" fill={filters.showFavorites ? "currentColor" : "none"} />
                  Favorites
                </button>
                
                <button 
                  onClick={() => setFilters({...filters, showAllNotes: !filters.showAllNotes})}
                  className="flex items-center text-sm text-gray-300 hover:text-white w-full px-2 py-1.5 rounded hover:bg-gray-800"
                >
                  {filters.showAllNotes ? 
                    <CheckSquare size={16} className="mr-2 text-rose-500" /> : 
                    <Square size={16} className="mr-2 text-gray-500" />
                  }
                  <FileText size={14} className="mr-1.5 text-gray-400" />
                  Other notes
                </button>
              </div>
            </div>
            
            {/* Folders */}
            <div>
              <h4 className="text-xs uppercase font-semibold text-gray-500 mb-3">Folders</h4>
              
              <div className="space-y-1 max-h-40 overflow-y-auto pr-1">
                <button 
                  onClick={() => {
                    setFilters(prev => ({
                      ...prev,
                      selectedFolders: {
                        ...prev.selectedFolders,
                        uncategorized: !prev.selectedFolders.uncategorized
                      }
                    }));
                  }}
                  className="flex items-center text-sm text-gray-300 hover:text-white w-full px-2 py-1.5 rounded hover:bg-gray-800"
                >
                  {filters.selectedFolders['uncategorized'] ? 
                    <CheckSquare size={16} className="mr-2 text-rose-500" /> : 
                    <Square size={16} className="mr-2 text-gray-500" />
                  }
                  <Folder size={14} className="mr-1.5 text-gray-400" />
                  Uncategorized
                </button>
                
                {folders.map(folder => (
                  <button 
                    key={folder.id}
                    onClick={() => {
                      setFilters(prev => ({
                        ...prev,
                        selectedFolders: {
                          ...prev.selectedFolders,
                          [folder.id]: !prev.selectedFolders[folder.id]
                        }
                      }));
                    }}
                    className="flex items-center text-sm text-gray-300 hover:text-white w-full px-2 py-1.5 rounded hover:bg-gray-800"
                  >
                    {filters.selectedFolders[folder.id] ? 
                      <CheckSquare size={16} className="mr-2 text-rose-500" /> : 
                      <Square size={16} className="mr-2 text-gray-500" />
                    }
                    <span 
                      className="w-3 h-3 rounded-full mr-1.5" 
                      style={{ backgroundColor: getFolderColor(folder.id) }}
                    ></span>
                    {folder.name}
                  </button>
                ))}
              </div>
            </div>
            
            {/* Tags */}
            {tagsList.length > 0 && (
              <div>
                <h4 className="text-xs uppercase font-semibold text-gray-500 mb-3">Tags</h4>
                
                <div className="space-y-1 max-h-40 overflow-y-auto pr-1">
                  {tagsList.map((tag, index) => (
                    <button 
                      key={tag}
                      onClick={() => {
                        setFilters(prev => ({
                          ...prev,
                          selectedTags: {
                            ...prev.selectedTags,
                            [tag]: !prev.selectedTags[tag]
                          }
                        }));
                      }}
                      className="flex items-center text-sm text-gray-300 hover:text-white w-full px-2 py-1.5 rounded hover:bg-gray-800"
                    >
                      {filters.selectedTags[tag] ? 
                        <CheckSquare size={16} className="mr-2 text-rose-500" /> : 
                        <Square size={16} className="mr-2 text-gray-500" />
                      }
                      <span 
                        className="w-3 h-3 rounded-full mr-1.5" 
                        style={{ backgroundColor: getTagColor(index % 8) }}
                      ></span>
                      {tag}
                    </button>
                  ))}
                </div>
              </div>
            )}
            
            {/* Connection count filter */}
            <div>
              <h4 className="text-xs uppercase font-semibold text-gray-500 mb-3">Connections</h4>
              
              <div className="px-2">
                <label className="flex justify-between text-xs text-gray-400 mb-1">
                  Min Connections
                  <span>{filters.minConnections}</span>
                </label>
                <div className="flex items-center mb-3">
                  <input
                    type="range"
                    min="0"
                    max="10"
                    step="1"
                    value={filters.minConnections}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      minConnections: parseInt(e.target.value)
                    }))}
                    className="w-full h-1 bg-gray-800 rounded-lg appearance-none cursor-pointer"
                  />
                </div>
                
                <label className="flex justify-between text-xs text-gray-400 mb-1">
                  Max Connections
                  <span>{filters.maxConnections === 100 ? 'Any' : filters.maxConnections}</span>
                </label>
                <div className="flex items-center">
                  <input
                    type="range"
                    min="1"
                    max="100"
                    step="1"
                    value={filters.maxConnections}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      maxConnections: parseInt(e.target.value)
                    }))}
                    className="w-full h-1 bg-gray-800 rounded-lg appearance-none cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  // Search panel
  const renderSearchPanel = () => {
    return (
      <AnimatePresence>
        {showSearch && (
          <motion.div 
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={`absolute top-0 left-0 right-0 z-10 overflow-hidden`}
            style={{ backgroundColor: THEMES[theme].panel }}
          >
            <div className="p-3">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={16} />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search notes..."
                  className="w-full bg-gray-800 border border-gray-700 rounded-md pl-10 pr-10 py-2 text-sm text-white focus:outline-none focus:ring-1 focus:ring-rose-500"
                />
                {searchTerm && (
                  <button
                    onClick={() => setSearchTerm('')}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-white"
                  >
                    <X size={16} />
                  </button>
                )}
              </div>
              
              <div className="flex space-x-2 mt-2">
                <button
                  onClick={() => setSearchMode('title')}
                  className={`px-2 py-1 text-xs rounded transition-colors ${
                    searchMode === 'title' ? 'bg-rose-600 text-white' : 'bg-gray-800 text-gray-400 hover:text-white'
                  }`}
                >
                  Title
                </button>
                <button
                  onClick={() => setSearchMode('content')}
                  className={`px-2 py-1 text-xs rounded transition-colors ${
                    searchMode === 'content' ? 'bg-rose-600 text-white' : 'bg-gray-800 text-gray-400 hover:text-white'
                  }`}
                >
                  Content
                </button>
                <button
                  onClick={() => setSearchMode('tags')}
                  className={`px-2 py-1 text-xs rounded transition-colors ${
                    searchMode === 'tags' ? 'bg-rose-600 text-white' : 'bg-gray-800 text-gray-400 hover:text-white'
                  }`}
                >
                  Tags
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };
  
  // Sidebar edit panel - This is the new panel for graph editing controls
  const renderSidebarControls = () => {
    if (!showSidebarControls) return null;
    
    return (
      <div className="bg-slate-800 border-r border-gray-700 w-56 flex flex-col h-full overflow-hidden">
        <div className="p-3 border-b border-gray-700">
          <h3 className="text-sm font-medium text-white flex items-center">
            <Wrench size={14} className="mr-1.5 text-rose-500" />
            Graph Controls
          </h3>
          
          <div className="flex space-x-1 mt-3">
            <button
              onClick={() => setEditTab('nodes')}
              className={`flex-1 py-1.5 text-xs font-medium transition-colors rounded ${
                editTab === 'nodes' ? 'bg-rose-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
              }`}
            >
              Nodes
            </button>
            <button
              onClick={() => setEditTab('links')}
              className={`flex-1 py-1.5 text-xs font-medium transition-colors rounded ${
                editTab === 'links' ? 'bg-rose-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
              }`}
            >
              Links
            </button>
            <button
              onClick={() => setEditTab('advanced')}
              className={`flex-1 py-1.5 text-xs font-medium transition-colors rounded ${
                editTab === 'advanced' ? 'bg-rose-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
              }`}
            >
              Advanced
            </button>
          </div>
        </div>
        
        <div className="p-3 flex-grow overflow-auto">
          {editTab === 'nodes' && (
            <div className="space-y-4">
              <div>
                <h4 className="text-xs uppercase font-semibold text-gray-400 mb-2">Node Actions</h4>
                
                <div className="space-y-2">
                  <button
                    onClick={() => {
                      setIsCreatingNode(true);
                      setNewNodeData({ title: '', content: '', folder_id: null, tags: [] });
                    }}
                    className="w-full px-3 py-2 bg-slate-700 hover:bg-slate-600 text-white text-xs rounded-md transition-colors flex items-center"
                  >
                    <Plus size={14} className="mr-1.5" />
                    Create New Node
                  </button>
                  
                  {selectedNode && (
                    <>
                      <button
                        onClick={() => toggleFavorite(selectedNode)}
                        className="w-full px-3 py-2 bg-slate-700 hover:bg-slate-600 text-white text-xs rounded-md transition-colors flex items-center"
                      >
                        <Heart size={14} className="mr-1.5" fill={selectedNode.isFavorite ? "currentColor" : "none"} />
                        {selectedNode.isFavorite ? "Remove from Favorites" : "Add to Favorites"}
                      </button>
                      
                      <button
                        onClick={() => setConfirmDeleteNode(selectedNode)}
                        className="w-full px-3 py-2 bg-red-900 hover:bg-red-800 text-white text-xs rounded-md transition-colors flex items-center"
                      >
                        <Trash2 size={14} className="mr-1.5" />
                        Delete Selected Node
                      </button>
                    </>
                  )}
                </div>
              </div>
              
              {isCreatingNode && (
                <div className="mt-4 space-y-3 border-t border-gray-700 pt-3">
                  <h4 className="text-xs uppercase font-semibold text-gray-400">New Node</h4>
                  
                  <div>
                    <label className="block text-xs text-gray-400 mb-1">Title (required)</label>
                    <input
                      type="text"
                      value={newNodeData.title}
                      onChange={(e) => setNewNodeData({...newNodeData, title: e.target.value})}
                      className="w-full bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm text-white focus:outline-none focus:ring-1 focus:ring-rose-500"
                      placeholder="Node title..."
                    />
                  </div>
                  
                  <div>
                    <label className="block text-xs text-gray-400 mb-1">Content (optional)</label>
                    <textarea
                      value={newNodeData.content}
                      onChange={(e) => setNewNodeData({...newNodeData, content: e.target.value})}
                      className="w-full bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm text-white focus:outline-none focus:ring-1 focus:ring-rose-500 h-20"
                      placeholder="Node content..."
                    ></textarea>
                  </div>
                  
                  <div>
                    <label className="block text-xs text-gray-400 mb-1">Folder</label>
                    <select
                      value={newNodeData.folder_id || ''}
                      onChange={(e) => setNewNodeData({...newNodeData, folder_id: e.target.value ? e.target.value : null})}
                      className="w-full bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm text-white focus:outline-none focus:ring-1 focus:ring-rose-500"
                    >
                      <option value="">Uncategorized</option>
                      {folders.map(folder => (
                        <option key={folder.id} value={folder.id}>{folder.name}</option>
                      ))}
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-xs text-gray-400 mb-1">Tags (comma-separated)</label>
                    <input
                      type="text"
                      value={newNodeData.tags.join(', ')}
                      onChange={(e) => {
                        const tagsText = e.target.value;
                        const tags = tagsText.split(',').map(tag => tag.trim()).filter(tag => tag);
                        setNewNodeData({...newNodeData, tags});
                      }}
                      className="w-full bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm text-white focus:outline-none focus:ring-1 focus:ring-rose-500"
                      placeholder="tag1, tag2, tag3..."
                    />
                  </div>
                  
                  <div className="flex space-x-2 mt-3">
                    <button
                      onClick={handleCreateNode}
                      className="flex-1 px-3 py-2 bg-rose-600 hover:bg-rose-500 text-white text-xs rounded-md transition-colors"
                    >
                      Create Node
                    </button>
                    <button
                      onClick={() => setIsCreatingNode(false)}
                      className="px-3 py-2 bg-gray-700 hover:bg-gray-600 text-white text-xs rounded-md transition-colors"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              
              <div className="mt-4 space-y-2 border-t border-gray-700 pt-3">
                <h4 className="text-xs uppercase font-semibold text-gray-400 mb-2">Node Selection</h4>
                
                {selectedNode ? (
                  <div className="flex flex-col px-3 py-2 bg-slate-700 rounded-md">
                    <span className="text-xs font-medium text-white truncate">{selectedNode.title}</span>
                    <span className="text-xs text-gray-400">ID: {selectedNode.id}</span>
                    <div className="flex items-center mt-2 space-x-2">
                      <button
                        onClick={() => handleNodeDoubleClick(selectedNode)}
                        className="px-2 py-1 bg-rose-600 hover:bg-rose-500 text-white text-xs rounded transition-colors flex items-center"
                      >
                        <Eye size={12} className="mr-1" />
                        View
                      </button>
                      <button
                        onClick={() => {
                          setIsCreatingLink(true);
                          setLinkSourceNode(selectedNode);
                        }}
                        className="px-2 py-1 bg-slate-600 hover:bg-slate-500 text-white text-xs rounded transition-colors flex items-center"
                      >
                        <Link size={12} className="mr-1" />
                        Connect
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="text-xs text-gray-400 italic">
                    Click on a node to select it
                  </div>
                )}
              </div>
            </div>
          )}
          
          {editTab === 'links' && (
            <div className="space-y-4">
              <div>
                <h4 className="text-xs uppercase font-semibold text-gray-400 mb-2">Link Creation</h4>
                
                <div className="space-y-2">
                  <button
                    onClick={() => {
                      setIsCreatingLink(true);
                      setLinkSourceNode(null);
                    }}
                    className={`w-full px-3 py-2 ${
                      isCreatingLink 
                        ? 'bg-rose-700 hover:bg-rose-600'
                        : 'bg-slate-700 hover:bg-slate-600'
                    } text-white text-xs rounded-md transition-colors flex items-center`}
                  >
                    <Link size={14} className="mr-1.5" />
                    {isCreatingLink ? 'Cancel Connection Mode' : 'Create New Connection'}
                  </button>
                </div>
                
                {isCreatingLink && (
                  <div className="mt-3">
                    <label className="block text-xs text-gray-400 mb-1">Connection Type</label>
                    <select
                      value={linkType}
                      onChange={(e) => setLinkType(e.target.value)}
                      className="w-full bg-gray-800 border border-gray-700 rounded px-2 py-1 text-sm text-white focus:outline-none focus:ring-1 focus:ring-rose-500"
                    >
                      {LINK_TYPES.map(type => (
                        <option key={type.id} value={type.id}>{type.name}</option>
                      ))}
                    </select>
                    
                    <div className="mt-2 p-2 bg-slate-700 rounded text-xs text-rose-300">
                      {linkSourceNode 
                        ? `Select a target node to connect from "${linkSourceNode.title}"`
                        : 'First, select a source node'
                      }
                    </div>
                  </div>
                )}
              </div>
              
              {selectedLink && (
                <div className="mt-4 space-y-3 border-t border-gray-700 pt-3">
                  <h4 className="text-xs uppercase font-semibold text-gray-400">Selected Connection</h4>
                  
                  <div className="px-3 py-2 bg-slate-700 rounded-md">
                    <div className="flex justify-between items-start">
                      <div>
                        <span className="text-xs font-medium text-white">
                          {LINK_TYPES.find(lt => lt.id === selectedLink.type)?.name || 'Connection'}
                        </span>
                        <div className="text-xs text-gray-400 mt-1">
                          <span className="block">From: {
                            typeof selectedLink.source === 'object' 
                              ? selectedLink.source.title 
                              : graphData.nodes.find(n => n.id === selectedLink.source)?.title || 'Unknown'
                          }</span>
                          <span className="block">To: {
                            typeof selectedLink.target === 'object' 
                              ? selectedLink.target.title 
                              : graphData.nodes.find(n => n.id === selectedLink.target)?.title || 'Unknown'
                          }</span>
                        </div>
                      </div>
                      
                      <button
                        onClick={() => setConfirmDeleteLink(selectedLink)}
                        className="p-1 text-gray-400 hover:text-red-400"
                        title="Delete connection"
                      >
                        <Scissors size={16} />
                      </button>
                    </div>
                    
                    <div className="mt-3">
                      <label className="block text-xs text-gray-400 mb-1">Change Type</label>
                      <div className="flex flex-wrap gap-1 mt-1">
                        {LINK_TYPES.map(type => (
                          <button
                            key={type.id}
                            onClick={() => handleChangeLinkType(selectedLink, type.id)}
                            className={`px-2 py-1 text-xs rounded ${
                              selectedLink.type === type.id
                                ? 'bg-rose-600 text-white'
                                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                            }`}
                            style={{ borderLeft: `3px solid ${type.color}` }}
                          >
                            {type.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              <div className="mt-4 space-y-3 border-t border-gray-700 pt-3">
                <h4 className="text-xs uppercase font-semibold text-gray-400 mb-2">Connection Types</h4>
                
                <div className="space-y-2">
                  {LINK_TYPES.map(type => (
                    <div key={type.id} className="flex items-center text-xs text-gray-300">
                      <div 
                        className="w-4 h-2 rounded mr-2" 
                        style={{ backgroundColor: type.color }}
                      ></div>
                      {type.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          
          {editTab === 'advanced' && (
            <div className="space-y-4">
              <div>
                <h4 className="text-xs uppercase font-semibold text-gray-400 mb-2">Graph Editing Mode</h4>
                
                <button
                  onClick={() => setIsEditMode(!isEditMode)}
                  className={`w-full px-3 py-2 ${
                    isEditMode 
                      ? 'bg-rose-700 hover:bg-rose-600'
                      : 'bg-slate-700 hover:bg-slate-600'
                  } text-white text-xs rounded-md transition-colors flex items-center justify-center`}
                >
                  <Edit3 size={14} className="mr-1.5" />
                  {isEditMode ? 'Exit Edit Mode' : 'Enter Edit Mode'}
                </button>
                
                {isEditMode && (
                  <div className="mt-2 p-2 bg-slate-700 rounded text-xs text-gray-300">
                    <p>In edit mode, you can:</p>
                    <ul className="list-disc ml-4 mt-1 space-y-1">
                      <li>Click on nodes to select</li>
                      <li>Click on links to edit them</li>
                      <li>Create/delete connections</li>
                      <li>Drag nodes to organize</li>
                    </ul>
                  </div>
                )}
              </div>
              
              <div className="mt-4 space-y-3 border-t border-gray-700 pt-3">
                <h4 className="text-xs uppercase font-semibold text-gray-400 mb-2">Graph Stats</h4>
                
                <div className="space-y-2 text-xs text-gray-300">
                  <div className="flex justify-between">
                    <span>Total Notes:</span>
                    <span>{graphData.nodes.length}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Total Connections:</span>
                    <span>{graphData.links.length}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Favorites:</span>
                    <span>{graphData.nodes.filter(n => n.isFavorite).length}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Wiki Links:</span>
                    <span>{graphData.links.filter(l => l.type === 'wiki').length}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Mentions:</span>
                    <span>{graphData.links.filter(l => l.type === 'mention').length}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Custom Links:</span>
                    <span>{graphData.links.filter(l => l.type !== 'wiki' && l.type !== 'mention').length}</span>
                  </div>
                </div>
              </div>
              
              <div className="mt-4 space-y-3 border-t border-gray-700 pt-3">
                <h4 className="text-xs uppercase font-semibold text-gray-400 mb-2">Layout Tools</h4>
                
                <div className="space-y-2">
                  <button
                    onClick={() => toggleLayout('force')}
                    className="w-full px-3 py-2 bg-slate-700 hover:bg-slate-600 text-white text-xs rounded-md transition-colors flex items-center justify-center"
                  >
                    <Zap size={14} className="mr-1.5" />
                    Force Layout
                  </button>
                  <button
                    onClick={() => toggleLayout('radial')}
                    className="w-full px-3 py-2 bg-slate-700 hover:bg-slate-600 text-white text-xs rounded-md transition-colors flex items-center justify-center"
                  >
                    <Target size={14} className="mr-1.5" />
                    Radial Layout
                  </button>
                  <button
                    onClick={() => toggleLayout('hierarchy')}
                    className="w-full px-3 py-2 bg-slate-700 hover:bg-slate-600 text-white text-xs rounded-md transition-colors flex items-center justify-center"
                  >
                    <Layers size={14} className="mr-1.5" />
                    Hierarchical Layout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        
        <div className="p-3 border-t border-gray-700">
          <button
            onClick={() => setShowSidebarControls(false)}
            className="w-full px-3 py-1.5 bg-slate-700 hover:bg-slate-600 text-white text-xs rounded-md transition-colors flex items-center justify-center"
          >
            <ArrowLeft size={14} className="mr-1.5" />
            Hide Controls
          </button>
        </div>
      </div>
    );
  };
  
  // Empty state
  const renderEmptyState = () => {
    return (
      <div className="flex h-full items-center justify-center text-gray-400" 
            style={{ backgroundColor: THEMES[theme].background }}>
        <div className="flex flex-col items-center max-w-xs text-center p-6 rounded-lg bg-slate-800 bg-opacity-50 backdrop-blur">
          <svg className="mb-4 w-16 h-16 opacity-60" viewBox="0 0 100 100">
            <g fill="none" stroke="currentColor" strokeWidth="2">
              <circle cx="30" cy="30" r="8" />
              <circle cx="70" cy="40" r="8" />
              <circle cx="45" cy="70" r="8" />
              <line x1="34" y1="36" x2="41" y2="64" strokeDasharray="4 2" />
              <line x1="65" y1="46" x2="49" y2="65" strokeDasharray="4 2" />
              <line x1="37" y1="31" x2="63" y2="39" strokeDasharray="4 2" />
            </g>
          </svg>
          <h3 className="text-lg font-medium text-white mb-2">No matching notes</h3>
          <p className="mb-4">No notes found matching your current filters and search terms.</p>
          
          {searchTerm && (
            <button 
              onClick={() => setSearchTerm('')} 
              className="text-sm text-rose-500 hover:text-rose-400 mb-2"
            >
              Clear search
            </button>
          )}
          
          <button 
            onClick={() => {
              // Reset all filters
              const folderFilters = {};
              folders.forEach(folder => {
                folderFilters[folder.id] = true;
              });
              folderFilters['uncategorized'] = true;
              
              // Reset tag filters
              const tagFilters = {};
              const allTags = new Set();
              graphData.nodes.forEach(node => {
                if (node.tags && Array.isArray(node.tags)) {
                  node.tags.forEach(tag => allTags.add(tag));
                }
              });
              allTags.forEach(tag => {
                tagFilters[tag] = true;
              });
              
              setFilters({
                showFavorites: true,
                showAllNotes: true,
                selectedFolders: folderFilters,
                selectedTags: tagFilters,
                minConnections: 0,
                maxConnections: 100
              });
              
              setSearchTerm('');
            }}
            className="px-3 py-1.5 bg-rose-600 hover:bg-rose-500 text-white rounded-md text-sm transition-colors"
          >
            Reset all filters
          </button>
          
          {onCreateNote && (
            <button
              onClick={() => {
                setIsCreatingNode(true);
                setNewNodeData({ title: '', content: '', folder_id: null, tags: [] });
              }}
              className="mt-4 text-sm text-white hover:text-rose-200 flex items-center"
            >
              <Plus size={16} className="mr-1 text-rose-400" />
              Create new note
            </button>
          )}
        </div>
      </div>
    );
  };
  
  // Loading state
  const renderLoadingState = () => {
    return (
      <div className="flex h-full items-center justify-center" 
           style={{ backgroundColor: THEMES[theme].background }}>
        <div className="flex flex-col items-center">
          <div className="relative w-16 h-16">
            <div className="absolute inset-0 rounded-full border-4 border-rose-500 opacity-25 animate-ping"></div>
            <div className="absolute inset-0 rounded-full border-2 border-gray-500 border-t-rose-500 animate-spin"></div>
            <div className="absolute inset-0 rounded-full border border-rose-500 opacity-50 animate-pulse"></div>
          </div>
          <p className="mt-4 text-white text-lg">Loading knowledge graph...</p>
          <p className="mt-2 text-gray-400 text-sm">Mapping connections between your ideas</p>
        </div>
      </div>
    );
  };
  
  // Render confirmation modal for node deletion
  const renderDeleteNodeConfirmation = () => {
    if (!confirmDeleteNode) return null;
    
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
        <div className="bg-slate-800 rounded-lg shadow-xl max-w-md w-full p-4">
          <div className="flex items-center text-rose-500 mb-3">
            <AlertTriangle size={20} className="mr-2" />
            <h3 className="text-lg font-medium">Delete Node</h3>
          </div>
          
          <p className="text-gray-300 mb-4">
            Are you sure you want to delete <strong className="text-white">{confirmDeleteNode.title}</strong>? 
            This will remove the node and all its connections from the graph. This action cannot be undone.
          </p>
          
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setConfirmDeleteNode(null)}
              className="px-3 py-2 bg-gray-700 hover:bg-gray-600 text-white text-sm rounded"
            >
              Cancel
            </button>
            <button
              onClick={() => handleDeleteNode(confirmDeleteNode)}
              className="px-3 py-2 bg-rose-600 hover:bg-rose-500 text-white text-sm rounded flex items-center"
            >
              <Trash2 size={16} className="mr-1.5" />
              Delete Node
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  // Render confirmation modal for link deletion
  const renderDeleteLinkConfirmation = () => {
    if (!confirmDeleteLink) return null;
    
    const sourceNode = typeof confirmDeleteLink.source === 'object' 
      ? confirmDeleteLink.source 
      : graphData.nodes.find(n => n.id === confirmDeleteLink.source);
      
    const targetNode = typeof confirmDeleteLink.target === 'object' 
      ? confirmDeleteLink.target 
      : graphData.nodes.find(n => n.id === confirmDeleteLink.target);
    
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
        <div className="bg-slate-800 rounded-lg shadow-xl max-w-md w-full p-4">
          <div className="flex items-center text-rose-500 mb-3">
            <AlertTriangle size={20} className="mr-2" />
            <h3 className="text-lg font-medium">Delete Connection</h3>
          </div>
          
          <p className="text-gray-300 mb-4">
            Are you sure you want to delete the {LINK_TYPES.find(lt => lt.id === confirmDeleteLink.type)?.name || 'connection'} between 
            <strong className="text-white"> {sourceNode?.title || 'Unknown'}</strong> and 
            <strong className="text-white"> {targetNode?.title || 'Unknown'}</strong>?
            This action cannot be undone.
          </p>
          
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setConfirmDeleteLink(null)}
              className="px-3 py-2 bg-gray-700 hover:bg-gray-600 text-white text-sm rounded"
            >
              Cancel
            </button>
            <button
              onClick={() => handleDeleteLink(confirmDeleteLink)}
              className="px-3 py-2 bg-rose-600 hover:bg-rose-500 text-white text-sm rounded flex items-center"
            >
              <Scissors size={16} className="mr-1.5" />
              Delete Connection
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  // Get current theme colors
  const themeColors = THEMES[theme] || THEMES.dark;
  
  // Main back button for when graph is shown in main view
  const renderBackButton = () => {
    if (!isMainView || !onBackToExplorer) return null;
    
    return (
      <button
        onClick={onBackToExplorer}
        className="absolute top-4 left-4 z-20 bg-slate-800 p-2 rounded-full shadow-lg flex items-center justify-center hover:bg-slate-700 transition-colors group"
      >
        <ArrowLeft size={20} className="text-gray-400 group-hover:text-white" />
      </button>
    );
  };
  
  // Render the sidebar toggle button when sidebar is hidden
  const renderSidebarToggle = () => {
    if (showSidebarControls) return null;
    
    return (
      <button
        onClick={() => setShowSidebarControls(true)}
        className="absolute top-20 left-4 z-20 bg-slate-800 p-2 rounded-full shadow-lg flex items-center justify-center hover:bg-slate-700 transition-colors group"
      >
        <Edit3 size={18} className="text-gray-400 group-hover:text-white" />
      </button>
    );
  };
  
  return (
    <div className="w-full h-full flex flex-col relative overflow-hidden"
         style={{ backgroundColor: themeColors.background }}>
      {/* Render back button when in main view */}
      {renderBackButton()}
      
      {/* Render sidebar toggle when sidebar is hidden */}
      {renderSidebarToggle()}
      
      {/* Top toolbar */}
      <div className="px-3 py-2 flex items-center justify-between z-20 border-b border-gray-800"
           style={{ backgroundColor: themeColors.panel }}>
        <div className="flex items-center space-x-1">
          <button 
            onClick={() => setShowSearch(!showSearch)} 
            className={`p-1.5 rounded transition-colors ${
              showSearch ? 'bg-rose-600 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800'
            }`}
            title="Search notes"
          >
            <Search size={16} />
          </button>
          
          <button 
            onClick={() => setVisibleRightPanel(visibleRightPanel === 'filter' ? null : 'filter')} 
            className={`p-1.5 rounded transition-colors ${
              visibleRightPanel === 'filter' ? 'bg-rose-600 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800'
            }`}
            title="Filter graph"
          >
            <Filter size={16} />
          </button>
          
          <button 
            onClick={() => setVisibleRightPanel(visibleRightPanel === 'settings' ? null : 'settings')} 
            className={`p-1.5 rounded transition-colors ${
              visibleRightPanel === 'settings' ? 'bg-rose-600 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800'
            }`}
            title="Graph settings"
          >
            <Sliders size={16} />
          </button>
          
          <div className="h-4 border-r border-gray-700 mx-1"></div>
          
          <button 
            onClick={() => setIs3D(!is3D)} 
            className={`p-1.5 rounded transition-colors text-gray-400 hover:text-white hover:bg-gray-800`}
            title={is3D ? "Switch to 2D" : "Switch to 3D"}
          >
            {is3D ? <Box size={16} /> : <Layers size={16} />}
          </button>
          
          <button 
            onClick={() => setIsEditMode(!isEditMode)} 
            className={`p-1.5 rounded transition-colors ${
              isEditMode ? 'bg-rose-600 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800'
            }`}
            title="Toggle edit mode"
          >
            <Edit3 size={16} />
          </button>
        </div>
        
        <div className="text-xs text-gray-400 flex items-center">
          {isCreatingLink && (
            <span className="mr-3 bg-rose-900/50 text-rose-300 px-2 py-0.5 rounded-full text-xs animate-pulse">
              {linkSourceNode ? 'Select target node' : 'Select source node'}
            </span>
          )}
          
          {isEditMode && (
            <span className="mr-3 bg-rose-900/50 text-rose-300 px-2 py-0.5 rounded">
              Edit Mode
            </span>
          )}
          
          <span>
            {filteredGraphData.nodes.length} notes • {filteredGraphData.links.length} connections
          </span>
        </div>
        
        <div className="flex items-center space-x-1">
          <button 
            onClick={() => setShowNodePanel(!showNodePanel)} 
            className={`p-1.5 rounded transition-colors ${
              selectedNode && showNodePanel ? 'text-rose-400 hover:text-rose-300' : 'text-gray-400 hover:text-white hover:bg-gray-800'
            }`}
            title={showNodePanel ? "Hide node panel" : "Show node panel"}
          >
            <PanelRight size={16} />
          </button>
          
          {onCreateNote && (
            <button 
              onClick={() => {
                setIsCreatingNode(true);
                setNewNodeData({ title: '', content: '', folder_id: null, tags: [] });
              }} 
              className="p-1.5 rounded transition-colors text-gray-400 hover:text-white hover:bg-gray-800"
              title="Create new note"
            >
              <Feather size={16} />
            </button>
          )}
        </div>
      </div>
      
      {/* Search panel */}
      {renderSearchPanel()}
      
      {/* Main content area */}
      <div className="flex-grow flex overflow-hidden relative">
        {/* Sidebar edit controls */}
        {renderSidebarControls()}
        
        {/* Graph container */}
        <div className="flex-grow relative">
          {isLoading ? (
            renderLoadingState()
          ) : filteredGraphData.nodes.length === 0 ? (
            renderEmptyState()
          ) : (
            <>
              {is3D ? (
                <ForceGraph3D
                  ref={graph3DRef}
                  graphData={filteredGraphData}
                  nodeRelSize={6 * settings.nodeSize}
                  nodeColor={getNodeColor}
                  nodeThreeObject={settings.showLabels ? getNodeThreeObject : null}
                  linkColor={getLinkColor}
                  linkWidth={getLinkWidth}
                  linkDirectionalParticles={settings.particlesPerLink}
                  linkDirectionalParticleSpeed={settings.particleSpeed}
                  linkDirectionalArrowLength={settings.showArrows ? 5 : 0}
                  linkDirectionalArrowRelPos={0.8}
                  backgroundColor={themeColors.background}
                  onNodeClick={handleNodeClick}
                  onNodeDblClick={handleNodeDoubleClick}
                  onLinkClick={handleLinkClick}
                  onBackgroundClick={handleBackgroundClick}
                  cooldownTicks={100}
                  d3AlphaDecay={0.02}
                  d3VelocityDecay={0.3}
                  d3Force="charge"
                  d3ForceStrength={settings.chargeStrength}
                  warmupTicks={100}
                  cooldownTime={3000}
                />
              ) : (
                <ForceGraph2D
                  ref={graph2DRef}
                  graphData={filteredGraphData}
                  nodeRelSize={6 * settings.nodeSize}
                  nodeLabel={null} // We'll handle our own labels
                  nodeCanvasObject={getNodeCanvasObject}
                  linkColor={getLinkColor}
                  linkWidth={getLinkWidth}
                  linkDirectionalParticles={settings.particlesPerLink}
                  linkDirectionalParticleSpeed={settings.particleSpeed}
                  linkDirectionalArrowLength={settings.showArrows ? 3 : 0}
                  linkDirectionalArrowRelPos={0.8}
                  backgroundColor={themeColors.background}
                  onNodeClick={handleNodeClick}
                  onNodeDblClick={handleNodeDoubleClick}
                  onNodeHover={node => setHoverNode(node)}
                  onLinkClick={handleLinkClick}
                  onBackgroundClick={handleBackgroundClick}
                  cooldownTicks={animateNodes ? 200 : 100}
                  d3AlphaDecay={animateNodes ? 0.01 : 0.02}
                  d3VelocityDecay={animateNodes ? 0.2 : 0.3}
                  d3Force="charge"
                  d3ForceStrength={settings.chargeStrength}
                  warmupTicks={animateNodes ? 200 : 100}
                  cooldownTime={animateNodes ? 5000 : 3000}
                />
              )}
              
              {/* Zoom controls */}
              <div className="absolute bottom-4 right-4 flex flex-col bg-slate-800 bg-opacity-80 rounded-lg overflow-hidden shadow-lg border border-slate-700">
                <button 
                  onClick={zoomIn}
                  className="p-2 text-gray-400 hover:text-white hover:bg-slate-700 transition-colors"
                  title="Zoom in"
                >
                  <ZoomIn size={16} />
                </button>
                <button 
                  onClick={zoomOut}
                  className="p-2 text-gray-400 hover:text-white hover:bg-slate-700 transition-colors"
                  title="Zoom out"
                >
                  <ZoomOut size={16} />
                </button>
                <button 
                  onClick={resetZoom}
                  className="p-2 text-gray-400 hover:text-white hover:bg-slate-700 transition-colors"
                  title="Fit view"
                >
                  <Minimize size={16} />
                </button>
                <div className="border-t border-slate-700"></div>
                <button 
                  onClick={() => {
                    const graphRef = is3D ? graph3DRef : graph2DRef;
                    if (graphRef.current) {
                      graphRef.current.d3ReheatSimulation();
                      setAnimateNodes(true);
                      setTimeout(() => setAnimateNodes(false), 2000);
                    }
                  }}
                  className="p-2 text-gray-400 hover:text-white hover:bg-slate-700 transition-colors"
                  title="Reheat simulation"
                >
                  <RefreshCw size={16} />
                </button>
              </div>
              
              {/* Legend */}
              <div className="absolute bottom-4 left-4 bg-slate-800 bg-opacity-80 rounded-lg overflow-hidden shadow-lg border border-slate-700 p-2">
                <div className="text-xs text-gray-400 mb-1.5 flex items-center">
                  <Compass size={12} className="mr-1" />
                  Legend
                </div>
                <div className="flex flex-col space-y-1.5">
                  {LINK_TYPES.map(type => (
                    <div key={type.id} className="flex items-center">
                      <div className="w-4 h-1 rounded" style={{ backgroundColor: type.color }}></div>
                      <span className="text-xs text-gray-300 ml-2">{type.name}</span>
                    </div>
                  ))}
                  <div className="flex items-center">
                    <div className="w-3 h-3 rounded-full" style={{ backgroundColor: COLORS.node.favorite }}></div>
                    <span className="text-xs text-gray-300 ml-2">Favorite</span>
                  </div>
                  {isEditMode && (
                    <div className="flex items-center">
                      <div className="w-3 h-3 rounded-full bg-rose-500"></div>
                      <span className="text-xs text-gray-300 ml-2">Edit Mode</span>
                    </div>
                  )}
                  {isCreatingLink && (
                    <div className="flex items-center">
                      <div className="w-3 h-3 rounded-full bg-rose-500 animate-pulse"></div>
                      <span className="text-xs text-gray-300 ml-2">Creating Link</span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        
        {/* Right sidebar - Node info panel */}
        <AnimatePresence>
          {selectedNode && showNodePanel && visibleRightPanel !== 'settings' && visibleRightPanel !== 'filter' && (
            <motion.div 
              initial={{ width: 0, opacity: 0 }}
              animate={{ width: 300, opacity: 1 }}
              exit={{ width: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="h-full border-l border-gray-800 overflow-hidden flex flex-col"
              style={{ backgroundColor: themeColors.panel }}
            >
              <div className="flex items-center justify-between p-2 border-b border-gray-800">
                <h3 className="text-sm font-medium text-gray-300 flex items-center">
                  <Info size={14} className="mr-1.5 text-rose-500" />
                  Note Details
                </h3>
                <button 
                  onClick={() => setShowNodePanel(false)}
                  className="p-1 rounded hover:bg-gray-800 text-gray-400 hover:text-white"
                >
                  <X size={16} />
                </button>
              </div>
              
              <div className="flex-grow overflow-hidden">
                {renderNodeInfoPanel()}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        
        {/* Right sidebar - Settings or Filter panel */}
        <AnimatePresence>
          {(visibleRightPanel === 'settings' || visibleRightPanel === 'filter') && (
            <motion.div 
              initial={{ width: 0, opacity: 0 }}
              animate={{ width: 320, opacity: 1 }}
              exit={{ width: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="h-full border-l border-gray-800 overflow-hidden flex flex-col"
              style={{ backgroundColor: themeColors.panel }}
            >
              <div className="flex items-center justify-between p-2 border-b border-gray-800">
                <h3 className="text-sm font-medium text-gray-300 flex items-center">
                  {visibleRightPanel === 'settings' ? (
                    <>
                      <Sliders size={14} className="mr-1.5 text-rose-500" />
                      Graph Settings
                    </>
                  ) : (
                    <>
                      <Filter size={14} className="mr-1.5 text-rose-500" />
                      Filter Graph
                    </>
                  )}
                </h3>
                <button 
                  onClick={() => setVisibleRightPanel(null)}
                  className="p-1 rounded hover:bg-gray-800 text-gray-400 hover:text-white"
                >
                  <X size={16} />
                </button>
              </div>
              
              <div className="flex-grow overflow-hidden">
                {renderRightPanel()}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      
      {/* Bottom toolbar */}
      <div className={`flex items-center justify-between px-3 py-1 border-t border-gray-800 z-10 ${
        isCreatingLink ? 'bg-rose-900/30' : ''
      }`} style={{ backgroundColor: themeColors.panel }}>
        <div className="text-xs text-gray-400 flex items-center">
          {isCreatingLink ? (
            <span className="text-rose-300 flex items-center">
              <Link size={12} className="mr-1 animate-pulse" />
              {linkSourceNode 
                ? `Creating link from "${linkSourceNode.title}" - click target note` 
                : 'Select source note to create a link'}
            </span>
          ) : isEditMode ? (
            <span className="text-rose-300 flex items-center">
              <Edit3 size={12} className="mr-1" />
              Edit Mode - Click nodes or connections to modify
            </span>
          ) : (
            <span>
              Knowledge Graph
            </span>
          )}
        </div>
        
        <div className="flex items-center space-x-2">
          <div className="text-xs text-gray-500 flex items-center">
            <Mouse size={12} className="mr-1" />
            <span>Click: Select</span>
            <span className="mx-1">•</span>
            <span>Double-click: Open</span>
          </div>
          
          {isCreatingLink && (
            <button
              onClick={() => {
                setIsCreatingLink(false);
                setLinkSourceNode(null);
              }}
              className="text-xs bg-rose-600 hover:bg-rose-500 text-white px-2 py-0.5 rounded transition-colors"
            >
              Cancel Linking
            </button>
          )}
        </div>
      </div>
      
      {/* Confirmation modals */}
      {renderDeleteNodeConfirmation()}
      {renderDeleteLinkConfirmation()}
    </div>
  );
};

export default GraphView;